<template>
  <div>
    <van-nav-bar title="通知详情" @click-left="onClickLeft" left-arrow />
    <div v-if="noticeDetail" class="notice_info">
      <div class="notice_title">{{noticeDetail.title}}</div>
      <div class="notice_time">{{noticeDetail.create_time}}</div>
      <div class="notice_lunch">午餐停餐时间：{{noticeDetail.am_start_time}}至{{noticeDetail.am_end_time}}</div>
      <div class="notice_dinner">晚餐停餐时间：{{noticeDetail.pm_start_time}}至{{noticeDetail.pm_end_time}}</div>
    </div>
    <div v-html="noticeDetail.content" class="notice_content"></div>
  </div>
</template>

<script>
import { noticerecordView } from "../../api/all";
export default {
  created() {
    this.getNoticeDetail();
  },
  data() {
    return {
      noticeDetail: null,
    };
  },
  methods: {
    async getNoticeDetail() {
      var id = this.$route.query.id;
      var res = await noticerecordView({id});
      if (res.status == '200') {
        this.noticeDetail = res.data;
      }
    },
    // 返回
    onClickLeft() {
      // this.$router.go(-1);
      if (this.$route.query.fromBlank) {
        this.$router.replace({ path: "/meal" })
      }else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.notice_info {
  width: 92vw;
  margin: 5vw auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.notice_title {
  font-size: 4vw;
  color: #212121;
  font-weight: bold;
}
.notice_time {
  font-size: 3vw;
  color: #999999;
  margin: 3vw 0;
}
.notice_lunch {
  font-size: 3vw;
  color: #3b3b3b;
  margin-bottom: 3vw;
}
.notice_dinner {
  font-size: 3vw;
  color: #3b3b3b;
  margin-bottom: 3vw;
}
.notice_content {
  padding: 0 4vw;
}
</style>