<template>
  <div>
    <van-nav-bar
      title="回收统计"
      left-text=""
      right-text=""
      :border="false"
    />
    <div class="list_back">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <div v-if="recoveryList.length == 0" style="padding: 6vw;">暂无数据</div>
      <div class="list_item" v-for="(item, index) in recoveryList" :key="index" >
        <div class="list_mid">
          <div class="item_date"><span class="date_span">日期：{{ item.time }}</span><span class="enterprise_span">{{ item.inc_name }}</span></div>
          <div class="title_back">
            <span>时段</span>
            <span>状态</span>
            <span>盒饭</span>
            <span>饭箱</span>
            <span>汤桶</span>
            <span>回收率</span>
          </div>
          <div v-if="item.am_ameal_require_num == null" class="item_back">
            <div>上午</div>
            <div class="doblue_info">
              <span>领取</span>
              <span>回收</span>
            </div>
            <div class="doblue_info">
              <span><span class="red_text">0</span>&nbsp;<span class="blue_text">0</span></span>
              <span><span class="red_text">0</span>&nbsp;<span class="blue_text">0</span></span>
            </div>
            <div class="doblue_info">
              <span>0</span>
              <span>0</span>
            </div>
            <div class="doblue_info">
              <span>0</span>
              <span>0</span>
            </div>
            <div>0.00%</div>
          </div>
          <div v-else class="item_back">
            <div>上午</div>
            <div class="doblue_info">
              <span>领取</span>
              <span>回收</span>
            </div>
            <div class="doblue_info">
              <span><span class="red_text">{{ item.am_ameal_require_num }}</span>&nbsp;<span class="blue_text">{{ item.am_bmeal_require_num }}</span></span>
              <span><span class="red_text">{{ item.am_ameal_actual_num }}</span>&nbsp;<span class="blue_text">{{ item.am_bmeal_actual_num }}</span></span>
            </div>
            <div class="doblue_info">
              <span>{{ item.am_case_require_num }}</span>
              <span>{{ item.am_case_actual_num }}</span>
              
            </div>
            <div class="doblue_info">
              <span>{{ item.am_bucket_require_num }}</span>
              <span>{{ item.am_bucket_actual_num }}</span>
            </div>
            <div>{{ item | calcRecycleRatio(1) }}%</div>
          </div>
          
          <div v-if="item.pm_ameal_require_num == null" class="item_back">
            <div>下午</div>
            <div class="doblue_info">
              <span>领取</span>
              <span>回收</span>
            </div>
            <div class="doblue_info">
              <span><span class="red_text">0</span>&nbsp;<span class="blue_text">0</span></span>
              <span><span class="red_text">0</span>&nbsp;<span class="blue_text">0</span></span>
            </div>
            <div class="doblue_info">
              <span>0</span>
              <span>0</span>
            </div>
            <div class="doblue_info">
              <span>0</span>
              <span>0</span>
            </div>
            <div>0.00%</div>
          </div>
          <div v-else class="item_back">
            <div>下午</div>
            <div class="doblue_info">
              <span>领取</span>
              <span>回收</span>
            </div>
            <div class="doblue_info">
              <span><span class="red_text">{{ item.pm_ameal_require_num }}</span>&nbsp;<span class="blue_text">{{ item.pm_bmeal_require_num }}</span></span>
              <span><span class="red_text">{{ item.pm_ameal_actual_num }}</span>&nbsp;<span class="blue_text">{{ item.pm_bmeal_actual_num }}</span></span>
            </div>
            <div class="doblue_info">
              <span>{{ item.pm_case_require_num }}</span>
              <span>{{ item.pm_case_actual_num }}</span>
            </div>
            <div class="doblue_info">
              
              <span>{{ item.pm_bucket_require_num }}</span>
              <span>{{ item.pm_bucket_actual_num }}</span>
            </div>
            <div>{{ item | calcRecycleRatio(2) }}%</div>
          </div>

        </div>
        <div class="rec_back">
          <div @click="recovery(item)" class="rec_btn">
            回收饭盒
          </div>
        </div>
      </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { recoveryIndex } from "../../api/all";
export default {
  name: 'Recovery',
  data() {
    return {
      loading: true,
      finished: false,
      list: [],
      recoveryList: [],
      page: 0,
      recycleDate: null,
    };
  },
  created() {
    this.loading = false;
    this.recycleDate = this.$moment().format("YYYY-MM-DD");
    this.getRecoveryIndex();
  },
  filters: {
    calcRecycleRatio(item,type) {
      var actual = 0;
      var require = 0;
      var res = 0;
      if (type == 1) {
        var {am_ameal_actual_num,am_bmeal_actual_num,am_bucket_actual_num,am_case_actual_num, am_ameal_require_num,am_bmeal_require_num,am_bucket_require_num,am_case_require_num} = item;
        actual = am_ameal_actual_num+am_bmeal_actual_num+am_bucket_actual_num+am_case_actual_num;
        require = am_ameal_require_num+am_bmeal_require_num+am_bucket_require_num+am_case_require_num;
        res = actual/require*100;
      }
      if (type == 2) {
        var {pm_ameal_actual_num,pm_bmeal_actual_num,pm_bucket_actual_num,pm_case_actual_num, pm_ameal_require_num,pm_bmeal_require_num,pm_bucket_require_num,pm_case_require_num} = item;
        actual = pm_ameal_actual_num+pm_bmeal_actual_num+pm_bucket_actual_num+pm_case_actual_num;
        require = pm_ameal_require_num+pm_bmeal_require_num+pm_bucket_require_num+pm_case_require_num;
        res = actual/require*100;
      }
      return res.toFixed(2);
    },
  },
  methods: {
    // "am_ameal_require_num": 20,
    // "am_bmeal_require_num": 40,
    // "am_bucket_require_num": 1,
    // "am_case_require_num": 3,
    // "am_ameal_actual_num": 0,
    // "am_bmeal_actual_num": 0,
    // "am_bucket_actual_num": 0,
    // "am_case_actual_num": 0,
    
    async getRecoveryIndex() {
      var args = {page: this.page+1,limit: 20,time: this.recycleDate};
      var res = await recoveryIndex(args);
      if (res.status == '200' && res.data.data.length > 0) {
        this.recoveryList = this.recoveryList.concat(res.data.data);
        this.page = this.page + 1;
      }
    },
    recovery(info){
      console.log(1254,info);
      this.$router.push({name: 'sendRecovery', query: info});
    },
    onLoad(){
      this.list = [
        {
          id: 1,
          day: '2010/01/01',
          enterprise: '西华村原油开采工场',
          morning: {
            'receive' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            'recovery' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            ratio: 50,
          },
          afternoon: {
            'receive' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            'recovery' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 5,
              'bucket': 10,
            },
            ratio: 30,
          }
        },
        {
          id: 2,
          day: '2010/01/01',
          enterprise: '西华村原油开采工场',
          morning: {
            'receive' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            'recovery' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            ratio: 50,
          },
          afternoon: {
            'receive' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 10,
              'bucket': 10,
            },
            'recovery' : {
              'rice': {
                red: 50,
                blue: 20,
              },
              'box': 5,
              'bucket': 10,
            },
            ratio: 30,
          }
        }
      ]
      this.loading = false
    }
  }
}
</script>
<style scoped>

  .list_back{
    padding: 3vw 1vw;
  }
  .list_item{
    border: solid 0vw #e5e5e5;
    margin-bottom: 3vw;
  }
  .list_mid{
    background-color: #ffffff;
    border-radius: 1vw;
  }
  .item_date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 4vw;
    border-bottom: 1px solid #e5e5e5;
  }
  .date_span{
    font-size: 3vw;
    color: #1e1e1e;
  }
  .enterprise_span{
    font-size: 3vw;
    color: #424242;
  }
  .title_back{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-size: 3vw;
    height: 8vw;
    border-bottom: 1px solid #e5e5e5;
  }
  .title_back > span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17vw;
  }
  .item_back{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11vw;
    padding: 3vw 0;
    font-size: 3vw;
    border-bottom: 1px solid #e5e5e5;
  }
  .item_back > div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17vw;
  }
  .doblue_info{
    padding: 3vw 0;
    flex-direction: column;
    justify-content: space-between!important;
    height: 100%;
  }
  .red_text{
    color: #ff0020;
  }
  .blue_text{
    color: #0084ff;
  }
  .rec_btn{
    width: 87vw;
    height: 9vw;
    background-color: #ef364d;
    border-radius: 0vw 0vw 7vw 7vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rec_back{
    display: flex;
    justify-content: center;
  }
</style>