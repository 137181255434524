<template>
  <div id="app">
    <!-- <keep-alive> -->
      <router-view v-if="judgerouter($route.name)" />
    <!-- </keep-alive> -->
    <router-view v-if="!judgerouter($route.name)" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      routing: ["meal", "notmeal", "notice", "order", "mine"],
    };
  },
  created() {
    
  },
  methods: {
    // 判断是否存在路由
    judgerouter(value) {
      var key = this.routing.find((item) => {
        return item == value;
      });
      if (key) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.notice_content img {
  max-width: 100%;
}
.wh_jiantou1,
.wh_jiantou2 {
  width: 6vw !important;
  height: 6vw !important;
  border-radius: 50% !important;
  transform: none !important;
  border: none !important;
}
.wh_jiantou1 {
  background: url("~@/assets/left.png") no-repeat;
  background-size: 100% 100%;
}
.wh_jiantou2 {
  background: url("~@/assets/right2.png") no-repeat;
  background-size: 100% 100%;
}
.wh_content_all {
  background-color: white !important;
}
.wh_content_item,
.wh_content_li {
  color: #3d3d3d !important;
}
.mark {
  background-color: #c30d23 !important;
  color: white !important;
  border-radius: 50%;
}
.van-tab__text {
  font-weight: bold;
}
.van-cell {
  padding: 10px 0!important;
  align-items: center;
}
.van-cell::after {
  position: static!important;
}
.van-collapse-item__content{
  padding: 12px 0!important;
  margin-bottom: 5vw;
  color: #3b3b3b;
  border: none;
}
.fold_right {
  font-size: 4vw;
  color: #c30d23!important;
  font-weight: bold;
}
.fold {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  /* margin: 3vw 0 5vw; */
}
.fold span {
  font-size: 3vw;
  color: #3b3b3b;
  font-weight: bold;
  position: relative;
  padding-left: 3vw;
  text-align: left;
}
.fold span::after {
  content: "";
  width: 1vw;
  height: 4vw;
  background-color: #c30d23;
  border-radius: 1vw;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.van-field__control{
    border-bottom: 1px solid #c30d23;
}
.amelset_center_single {
  margin: 1vw 0;
}

</style>
