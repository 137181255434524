import Vue from 'vue'


// 过滤器
Vue.filter('convertOrderStatusToText', function (orderStatus) {
  switch (orderStatus) {
      case 0:
      return '已取消';
      break;
      case 1:
      return '待付款';
      break;
      case 2:
      return '制作中';
      break;
      case 3:
      return '加餐确认';
      break;
      case 4:
      return '配送中';
      break;
      case 5:
      return '待收货';
      break;
      case 6:
      return '已完成';
      break;
      default:
      return '';
      break;
  }
});
