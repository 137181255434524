import request from "../utils/request";

// 企业和司机登录
export function memberLogin(args) {
  return request.post("member/Login", args);
}

// 获取菜单
export function getMenu(args) {
  return request.get("meal/getmenu",args);
}

// 添加订单
export function orderAdd(args) {
  return request.post("order/add", args);
}

// 获取菜单
export function memberinfo() {
  return request.get("member/memberinfo");
}

// 修改个人默认点餐
export function memberUpdate(args) {
  return request.post("member/update", {data: args,isLoading: true});
}

// 修改公司默认点餐信息
export function incUpdate(args) {
  return request.post("inc/update", {data: args,isLoading: true});
}

// 订单列表
export function orderIndex(args) {
  return request.get("order/index",args);
}

// 订单详情
export function orderView(args) {
  return request.get("order/view",args);
}

// 添加停餐申请
export function stopeatAdd(args) {
  return request.post("stopeat/add", {data:args});
}


// 停餐列表
export function stopeatIndex(args) {
  return request.get("stopeat/index",args);
}

// 通知列表
export function noticerecordIndex(args) {
  return request.get("noticerecord/index",args);
}

// 通知详情
export function noticerecordView(args) {
  return request.get("noticerecord/view",args);
}

// 通知详情
export function financeIndex(args) {
  return request.get("finance/index",args);
}

// 支付
export function orderPay(args) {
  return request.post("order/pay", {data:args});
}

// 更新订单
export function orderDetailUpdate(args) {
  return request.post("orderDetail/update", {data:args});
}

// 司机端订单列表
export function orderDriverList(args) {
  return request.get("order/driverList",args);
}

// 更新订单
export function orderUpdate(args) {
  return request.post("order/update", {data:args});
}

// 回收列表
export function recoveryIndex(args) {
  return request.get("recovery/index",args);
}

// 回收列表
export function recoveryUpdate(args) {
  return request.post("recovery/update",{data:args});
}

// 微信授权登录
export function memberWxlogin(args) {
  return request.post("wxpay/officeLogin",{data:args});
}

// 公司人员
export function memberIndex(args) {
  return request.get("member/index",args);
}

// 删除用户
export function memberDelete(args) {
  return request.get("member/delete", args);
}

// 获取openid
export function wxpayGetopenid(args) {
  return request.post("wxpay/getopenid",{data:args});
}

// 设置支付密码
export function memberSetPayPwd(args) {
  return request.post("Member/setPayPwd",{data:args});
}

// 账单详情
export function financeView(args) {
  return request.get("finance/view", args);
}

// 退出登录清除openid
export function memberLogout() {
  return request.get("member/logout");
}

// 取消订单
export function cancelOrder(args) {
  return request.post("order/cancelOrder",{data:args});
}