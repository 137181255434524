<template>
  <div>
    <van-nav-bar title="停餐申请" left-arrow @click-left="onClickLeft" />
    <div>
      <van-tabs
        v-model="active"
        @change="tabChange"
        title-active-color="#212121"
        title-inactive-color="#666666"
        line-width="35"
        line-height="10"
      >
        <van-tab title="午餐">
          <div class="lunch">
            <div class="lunch_tips">停止点餐：点击空白日期，即换位停止点餐</div>
            <div class="lunch_tips">取消申请：点击停止点餐日期，取消点餐</div>
            <Calendar v-on:choseDay="clickDay" v-on:changeMonth="changeDate" :markDateMore="arr"></Calendar>
            <div @click="confirmBtnClick(1)" class="lunch_bto">确认</div>
          </div>
        </van-tab>
        <van-tab title="晚餐">
          <div class="lunch">
            <div class="lunch_tips">停止点餐：点击空白日期，即换位停止点餐</div>
            <div class="lunch_tips">取消申请：点击停止点餐日期，取消点餐</div>
            <Calendar v-on:choseDay="clickDay" v-on:changeMonth="changeDate" :markDateMore="arr"></Calendar>
            <div @click="confirmBtnClick(2)" class="lunch_bto">确认</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Calendar from "vue-calendar-component";
import { stopeatIndex,stopeatAdd } from "../../api/all";
import { Toast } from 'vant';
export default {
  components: {
    Calendar,
  },
  created() {
    this.monthdash = this.$moment().format("YYYY-MM");
    this.monthList.push(this.$moment().format("YYYY/MM/DD"));
    this.getStopMealList();
  },

  data() {
    return {
      active: 0, //index位置
      arr: [],
      postArr: [],
      monthdash: '',
      monthList: [],
    };
  },
  methods: {
    tabChange(name, title) {
      this.arr = [];
      this.postArr = [];
      this.getStopMealList();
    },
    async getStopMealList() {
      var res = await stopeatIndex({date: this.monthdash,type: this.active+1});
      if (res.status == '200' && res.data.data.length > 0) {
        res.data.data.forEach(element => {
          element["date"] = element["stop_time"];
          element["className"] = "mark";
        });
        this.arr = res.data.data;
      }
    },
    async confirmBtnClick(type)  {
      var stop_time = this.postArr.map(item =>  {
        return item.date;
      })
      var res = await stopeatAdd({stop_time:stop_time.join(","),type});
      if (res.status == '200') {
        Toast(res.msg || "操作成功");
        this.$router.go(-1);
      }
      
    },
    //    返回
    onClickLeft() {
      this.$router.go(-1);
    },
    clickDay(data) {
      var value = this.arr.findIndex((item) => item.date == data);
      var postvalue = this.postArr.findIndex((item) => item.date == data);
      if (value == -1) {
        this.arr.push({
          date: data,
          className: "mark",
        });
      } else {
        var res = this.arr.splice(value, 1);
      }
      if (postvalue == -1) {
        this.postArr.push({
          date: data,
          className: "mark",
        });
      } else {
        var res = this.postArr.splice(postvalue, 1);
      }
    },
    changeDate(data) {
      this.monthdash = this.$moment(data).format("YYYY-MM");
      if (this.monthList.indexOf(data) == -1) {
        this.monthList.push(data);
        this.getStopMealList();
      }
    },
  },
};
</script>

<style scoped>
.lunch {
  width: 84vw;
  margin: 0 auto;
}
.lunch_tips {
  margin-top: 5vw;
  font-size: 3vw;
  text-align: left;
}
.lunch_bto {
  margin-top: 18vw;
  width: 84vw;
  height: 13vw;
  background-color: #c30d23;
  border-radius: 1vw;
  text-align: center;
  line-height: 13vw;
  color: white;
  font-size: 5vw;
}
</style>