<template>
  <div>
    <van-nav-bar
      title="配送"
      left-text=""
      right-text=""
      :border="false"
    />
    <div class="search-box">
      <input v-model="search" type="text" placeholder="请输入订单号或企业名称查找">
      <img @click="onSearch" src="@/assets/searchicon.png" alt="">
    </div>
    <div class="list_back">
      <van-tabs v-model="active" color="#fff" title-active-color="#c30d23">
        <van-tab title="待配送">
          <SendList :search="search" ref="sendlist1" :type="1" />
        </van-tab>
        <van-tab title="配送中" :badge="driverOnGoing">
          <SendList :search="search" ref="sendlist2" :type="2" />
        </van-tab>
        <van-tab title="已送达">
          <SendList :search="search" ref="sendlist3" :type="3" />
        </van-tab>
        <van-tab title="已完成">
          <SendList :search="search" ref="sendlist4" :type="4" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import searchicon from "@/assets/delete.png";
import SendList from './SendList.vue'
import { orderDriverList } from "../../api/all";
import {mapGetters} from 'vuex'
export default {
  name: 'Send',
  components: {
    SendList
  },
  data() {
    return {
      search: '',
      active: 0,
    };
  },
  computed: {
    ...mapGetters(['driverOnGoing'])
  },
  async created() {
    await this.$store.dispatch("getMemberInfo");
    await this.$store.dispatch("getDriverOnGoing");
  },
  methods: {
    onSearch(){
      var temp = `sendlist${this.active + 1}`;
      this.$refs[temp].searchNow(this.search);
    }
  }
}
</script>
<style scoped>
  .search_input{
    font-size: 3vw;
    height: 14vw;
    background-color: white;
    border-radius: 1vw;
    border: solid 0vw #cccccc;
    padding: 0 1vw;
  }
  .list_back{
    border-radius: 1vw;
    height: 13vw;
    padding: 0 1vw;
  }
  .search-box {
    height: 10vw;
    margin: 2vw 1vw;
    padding: 0 3vw;
    background-color: white;
    border-radius: 2vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .search-box input {
    flex: 1;
    border: none;
    outline: none;
    padding: 0 2vw;
  }
  .search-box input, .search-box input::placeholder {
    /* font-family: PingFang-SC-Regular; */
    font-size: 2.933vw;
    line-height: 3.2vw;
    color: #6d6d6d;
  }
  .search-box img{
    display: block;
    width: 5.067vw;
	height: 5.067vw;
  }
</style>