import Vue from "vue";
import Vuex, { Store } from "vuex";
import { memberinfo,orderDriverList } from "../api/all";

Vue.use(Vuex);
Vue.prototype.$bus = new Vue()

const state = {
  memberinfo: null,
  financeItem: null,
  driverOnGoing: 0,
};

const mutations = {
  saveMemberInfo(state,payload) {
    state.memberinfo = payload;
  },
  saveFinanceItem(state,payload) {
    state.financeItem = payload;
  },
  saveDriverOnGoing(state,payload) {
    state.driverOnGoing = payload;
  }
};

const actions =  {
  async getMemberInfo({commit}) {
    var res = await memberinfo();
    if (res.status == '200') {
      commit("saveMemberInfo",res.data)
    }
    return res.data;
  },
  async getDriverOnGoing({commit}) {
    console.log(this.searchKey,"聚聚");
      var args = {
        page: 1,
        limit: 999999999,
        order_status: 4
      };
      if (!isNaN(this.searchKey)) {
        args['order_num'] = this.searchKey;
      }else {
        args["name"] = this.searchKey;
      }
      var res = await orderDriverList(args);
      if (res.status == "200" && res.data.list) {
        commit("saveDriverOnGoing",res.data.list.length);
      }
  }
};

const getters = {
  memberInfo: state => {
    return state.memberinfo;
  },
  driverOnGoing: state => {
    return state.driverOnGoing;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
//   modules: {
//     gameReview,
//     friendBattleMode
//   },
});
