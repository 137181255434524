<template>
  <div style="height:100%">
    <div class="page_content_box">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.page_content_box {
  position: fixed;
  background-color: #f2f2f2;
  bottom: 15vw;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
</style>