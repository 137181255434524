<template>
  <div class="login">
    <div class="login_back">
      <img src="../assets/login/bc.png" class="login_backimg" />
    </div>
    <div>
      <div class="login_frame">
        <div class="login_frame_title">账号</div>
        <input type="text" v-model="loginArgs.username" placeholder="请输入账号" class="login_frame_input" />
      </div>
      <div class="login_frame">
        <div class="login_frame_title">密码</div>
        <input type="text" v-model="loginArgs.password" placeholder="请输入密码" class="login_frame_input" />
      </div>
    </div>
    <div class="login_bto" @click="login">登录</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { memberLogin, wxpayGetopenid } from "../api/all";
import { mapGetters } from 'vuex';
export default {
  name: "login",
  data() {
    return {
      loginArgs: {
        username: '',
        password: '',
      },
      code: null,
    };
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
  async created() {
    var oldRole = localStorage.getItem("role");
    if (oldRole != this.$route.query.role) {
      localStorage.clear();
    }
    localStorage.setItem("role",this.$route.query.role);
    var token = localStorage.getItem("token");
    if (token) {
      this.checkRoleAndJump();
    }else {
      await this.checkCode();
    }
  },
  methods: {
    // https://chuniange.qupinmall.com/?code=0013k51w3OSqUU2Mk71w3mJ6D623k51O&state=STATE#/
    async checkCode() {
      var href = window.location.href;
      // var code = this.$getCodeFromHref();
      var code = this.$getUrlKey('code');
      if (code) {
        this.code = code;
        return;
      }else if(navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc68be605ecabdb84&redirect_uri=${encodeURIComponent(
          `${this.$frontendurl}?role=${this.$route.query.role}`
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        // )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        return;
      }
      this.checkRoleAndJump();
    },
    async checkRoleAndJump() {
      var userinfo = await this.$store.dispatch("getMemberInfo");
      var type = userinfo.type;
      if (type != this.$route.query.role) {
        localStorage.clear();
        return;
      }
      if (type == 3 || type == 1) {
        // this.$router.replace({ path: "/tabbar" });
        window.location.href = `${window.location.origin}/#/tabbar`;
      }
      if (type == 2) {
        // this.$router.replace({ path: "/driver_tabbar"});
        window.location.href = `${window.location.origin}/#/driver_tabbar`;
      }
    },
    // 登陆跳转
    async login() {
      var role = this.$route.query.role;
      if (!role) return Toast("请从正确路径进入页面");
      var {loginArgs} = this;
      loginArgs["type"] = role;
      if (loginArgs.username.length == 0 || loginArgs.password.length == 0) {
        Toast("请填写完整信息！");
        return;
      }
      Toast.loading();
      var res = await memberLogin({data:loginArgs});
      if (res.status == '200') {
        localStorage.setItem("token",res.token);
        localStorage.setItem("userinfo",JSON.stringify(res.data));
        if (this.code) {
          var openidRes = await wxpayGetopenid({code:this.code});
        }
        await this.$store.dispatch("getMemberInfo");
        
        this.checkRoleAndJump();
      }
      Toast.clear();
      Toast(res.msg);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
}
.login_back {
  width: 100%;
  height: auto;
}
.login_backimg {
  width: 100%;
  height: 100%;
}
.login_frame {
  display: flex;
  align-items: center;
  width: 93.2vw;
  height: 11.733vw;
  background: white;
  border: 1px solid #cccccc;
  padding: 0 2.933vw;
  box-sizing: border-box;
  margin: 0 auto 4.267vw;
}
.login_frame_input {
  border: none;
  outline: none;
}
.login_frame_title {
  margin-right: 4.4vw;
  font-size: 4.267vw;
}
.login_bto {
  width: 84vw;
  height: 13vw;
  background-color: #c30d23;
  border-radius: 1vw;
  text-align: center;
  line-height: 13vw;
  color: white;
  font-size: 5vw;
  margin: 5vw auto 0;
}
</style>
