<template>
  <div class="company">
    <van-nav-bar title="公司人员" left-arrow @click-left="onClickLeft" />
    <div>
      <div class="company_top">
        <div class="company_top_left">
          <div>公司人员</div>
          <img src="../../assets/qrcode.png" class="company_qrcode" @click="show = true" />
        </div>
        <div class="company_top_right">{{total}}人</div>
      </div>
    </div>
    <div class="company_center">
      <div v-for="item in memberList" :key="item.id" class="company_single">
        <div class="company_single_left">
          <img v-if="!item.wx_head" src="../../assets/logo.png" class="logo" />
          <img v-else :src="item.wx_head" class="logo" />
          <div class="company_single_info">
            <div v-if="item.type == 3" class="company_single_name">
              <span>{{item.username}}</span>(管理员)
            </div>
            <div v-else class="company_single_name">
              <span>{{item.username}}</span>(微信昵称：{{item.nickname||"暂无"}})
            </div>
            <div v-if="item.type == 1" class="company_single_branch">{{item.department||"暂无部门信息"}}</div>
            <div v-else class="company_single_branch"> </div>
            <div>{{item.phone||'暂无'}}</div>
          </div>
        </div>
        <div class="company_single_icon">
          <img src="../../assets/edit.png" class="company_single_edit" @click="editnotes(item)" />
          <img src="../../assets/delete.png" class="company_single_detele" @click="deleteMemberConfirm(item)" />
        </div>
      </div>
      
    </div>
    <!-- 遮罩层 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="qrcode">
          <img class="saveImage" id="saveImage">
          <qrcode-vue :size="200" :value="qrcodeUrl" id="picture"></qrcode-vue>
        </div>
        <div class="longbap" @touchstart="start" @touchmove="end">长按保存二维码</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapGetters } from 'vuex';
import { memberIndex,memberDelete } from "../../api/all";
import { Toast,Dialog } from 'vant';
export default {
  data() {
    return {
      show: false,
      inc_id: null,
      inc_name: null,
      page: 0,
      memberList: [],
      total: 0,
    };
  },
  created() {
    this.getMemberIndex();
  },
  mounted() {
    var qrCanvas = document.getElementsByTagName('canvas')[0];
    var imgEl = new Image();
    imgEl.src = qrCanvas.toDataURL("image/png");
    document.getElementById('saveImage').src = imgEl.src;
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
    qrcodeUrl() {
      // http://chuniange.qupinmall.com/#/employeeInitInfo?inc_id=1&inc_name=测试啊啊啊啊
      // http://192.168.1.21:8080/#/employeeInitInfo?inc_id=1&inc_name=测试啊啊啊啊
      var inc_id = this.memberInfo.inc_id;
      var inc_name = this.memberInfo.inc_name;
      console.log(`${window.location.origin}/#/employeeInitInfo?inc_id=${inc_id}&inc_name=${inc_name}`);
      return `${window.location.origin}/#/employeeInitInfo?inc_id=${inc_id}&inc_name=${inc_name}`;
    }
  },
  components: {
    QrcodeVue,
  },
  methods: {
    deleteMemberConfirm(item) {
      if (item.type == 3) return Toast("无法删除管理员!");
      var id = item.id;
      Dialog.confirm({
        title: '提示',
        message: '是否确认删除该用户？',
        beforeClose: async (action, done) => {
          if (action === 'confirm') {
            var res = await memberDelete({id});
            if (res.status == '200') {
              this.page = 0;
              this.memberList = [];
              this.getMemberIndex();
            }
            done();
            Toast(res.msg);
          }else {
            done();
          }
          
        },
      });
    },
    async getMemberIndex() {
      var args = {
        page: this.page+1,
        limit: 20,
        inc_id: this.memberInfo.inc_id
      }
      var res = await memberIndex(args);
      if (res.status == '200' && res.data.list.length > 0) {
        this.memberList = this.memberList.concat(res.data.list);
        this.page = this.page + 1;
        this.total = res.data.count;
      }
    },
    // 返回上一层
    onClickLeft() {
      this.$router.go(-1);
    },
    // 长按事件
    start() {
      clearTimeout(this.loop); //再次清空定时器，防止重复注册定时器
      this.loop = setTimeout(() => {
        this.savePic();
      }, 1000);
    },

    end() {
      clearTimeout(this.loop); //清空定时器，防止重复注册定时器
    },
    // 长按保存
    savePic() {
      //找到canvas标签
      let myCanvas = document
        .getElementById("picture")
        .getElementsByTagName("canvas");
      //创建一个a标签节点
      let a = document.createElement("a");
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      //设置下载文件的名字
      a.download = "qrcode.jpg";
      //点击
      a.click();
    },
    // 跳转修改备注
    editnotes(item) {
      if (item.type == 3) return Toast("无法编辑管理员");
      this.$router.push({
        name: "editnotes",
        query: item,
      });
    },
  },
};
</script>

<style scoped>
.company {
  height: 100%;
  background-color: #f2f2f2;
}
.company_top {
  width: 97.333vw;
  height: 13.333vw;
  background-color: #ffffff;
  border-radius: 1.333vw;
  margin: 2.667vw auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.8vw;
  box-sizing: border-box;
}
.company_top_left {
  display: flex;
  align-items: center;
  font-size: 4.267vw;
  color: #212121;
  font-weight: bold;
}
.company_qrcode {
  width: 5.333vw;
  height: 5.333vw;
  margin-left: 1.733vw;
}
.company_top_right {
  font-size: 4.267vw;
  color: #666666;
}
.company_center {
  width: 97.333vw;
  background-color: #ffffff;
  border-radius: 1.333vw;
  margin: 0 auto;
}
.company_single {
  width: 94.667vw;
  height: 24.667vw;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.467vw 0 2vw;
  box-sizing: border-box;
}
.logo {
  border-radius: 6.1vw;
  width: 12.133vw;
  height: 12.133vw;
}
.company_single_info {
  font-size: 3.2vw;
  color: #000000;
  text-align: left;
  margin-left: 4.8vw;
}
.company_single_name span {
  font-size: 4.267vw;
  color: #212121;
  font-weight: bold;
  margin-right: 1.733vw;
}
.company_single_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.company_single_edit {
  width: 4.533vw;
  height: 4.533vw;
}
.company_single_detele {
  margin-top: 2.933vw;
  width: 4.533vw;
  height: 4.4vw;
}
.company_single_left {
  display: flex;
  align-items: center;
}
.company_single_branch {
  margin: 2vw 0;
}
.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 79.733vw;
  height: 91.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.qrcode {
  width: 54.667vw;
  height: 54.667vw;
  position: relative;
}
.saveImage {
  position: absolute;
  width: 210px;
  height: 210px;
}
.longbap {
  margin-top: 7.733vw;
  width: 46.4vw;
  height: 9.6vw;
  background-color: #c30d23;
  border-radius: 0.533vw;
  text-align: center;
  line-height: 9.6vw;
  color: white;
  font-size: 3.733vw;
}
</style>