<template>
  <div class="password">
    <van-nav-bar title="支付密码" left-arrow @click-left="onClickLeft" />
    <div class="password_all">
      <div class="password_name">密码</div>
      <div class="password_input">
        <input v-model="pay_pwd" type="text" class="password_value" placeholder="请输入密码" />
      </div>
    </div>
    <div class="password_all">
      <div class="password_name">确认密码</div>
      <div class="password_input">
        <input v-model="pay_pwd2" type="text" class="password_value" placeholder="请确认密码" />
      </div>
    </div>
    <div class="password_bto" @click="confim">确认</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { memberSetPayPwd } from "../../api/all";
export default {
  data() {
    return {
      pay_pwd: '',
      pay_pwd2: '',
    };
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 确认修改
    async confim() {
      var type = 2;//1 修改密码(要传旧密码)   2设置密码
      var {pay_pwd,pay_pwd2} = this;
      if (!pay_pwd || !pay_pwd2) return Toast("请填写必要信息！");
      if (pay_pwd !== pay_pwd2) return Toast("两次输入的密码不一致");
      var res = await memberSetPayPwd({pay_pwd,type});
      if (res.status == '200') {
        await this.$store.dispatch("getMemberInfo");
        this.$router.go(-1);
      }
      Toast(res.msg);
    },
  },
};
</script>

<style scoped>
.password {
  height: 100%;
  background-color: #f2f2f2;
}
.password_name {
  font-size: 3.467vw;
  color: #333333;
  text-align: left;
  padding-left: 2.933vw;
  margin: 4.4vw 0 2.4vw;
}
.password_input {
  width: 100%;
  height: 11.733vw;
  background-color: white;
}
.password_all {
  width: 97.333vw;
  margin: 0 auto;
}
.password_value {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  padding-left: 2.8vw;
  box-sizing: border-box;
}
.password_bto {
  width: 84.4vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  text-align: center;
  line-height: 13.467vw;
  color: white;
  margin: 6.533vw auto 0;
}
</style>