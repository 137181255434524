<template>
  <div class="page">
    <van-nav-bar title="停餐" />
    <div>
      <div class="notmeal_top">
        <div></div>
        <!-- <div>姓名：{{memberInfo.nickname}}{{memberInfo.type == 3 ? '【管理员】' : ''}}</div> -->
        <div class="notmeal_apply" @click="apply">停餐申请</div>
      </div>
      <div class="notmeal_specific">
        <div class="notmeal_specific_date">
          <div class="calendar_date">
            <img src="../../assets/calendar.png" class="calendar" />
            <div>{{month}}</div>
          </div>
          <div class="turn">
            <img @click="monthChange(-1)" src="../../assets/turnleft.png" class="turn_img" />
            <img @click="monthChange(1)" src="../../assets/turnright.png" class="turn_img" />
          </div>
        </div>
        <div class="specific_date">
          <div v-for="item in stopMealList" :key="item.id" class="specific_date_single">
            <div>{{item.stop_time}}</div>
            <div>{{item.type == 1 ? '午餐' : '晚餐'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjbGllbnQueGhhZG1pbiIsImF1ZCI6InNlcnZlci54aGFkbWluIiwiaWF0IjoxNTk5MTEyODEzLCJleHAiOjE2MzA2NDg4MTMsInVpZCI6N30.4oOMYtgZtFzi4g5u_kA2u2UeI9AMp7TqMVMP7dEhHaI
import { mapGetters } from 'vuex';
import { stopeatIndex } from "../../api/all";
export default {
  data() {
    return {
      month: '',
      monthdash: '',
      stopMealList: [],
    };
  },
  created() {
    this.enterPage();
  },
  activated() {
    this.enterPage();
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
  methods: {
    enterPage() {
      this.month = this.$moment().format("YYYY年MM月");
      this.monthdash = this.$moment().format("YYYY-MM");
      this.getStopMealList();
    },
    monthChange(val) {
      var timeList = this.monthdash.split("-");
      if (val == -1 && timeList[1] == '01') {
        timeList[0] = (parseInt(timeList[0]) - 1) + '';
        timeList[1] = '12';
      }else if (val == 1 && timeList[1] == '12') {
        timeList[0] = (parseInt(timeList[0]) + 1) + '';
        timeList[1] = '01';
      }else {
        timeList[1] = (parseInt(timeList[1]) + val) + '';
      }
      if (timeList[1].length == 1) {
        timeList[1] = '0' + timeList[1];
      }
      this.monthdash = timeList.join("-");
      this.month = timeList[0] + '年' + timeList[1] + '月';
      this.getStopMealList();
    },
    async getStopMealList() {
      var res = await stopeatIndex({date: this.monthdash});
      if (res.status == '200' && res.data.data.length > 0) {
        this.stopMealList = res.data.data;
      }
    },
    // 跳转到停餐申请
    apply() {
      this.$router.push({
        name: "apply",
      });
    },
  },
};
</script>

<style  scoped>
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.notmeal_top {
  display: flex;
  height: 13vw;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw;
}
.notmeal_apply {
  width: 22vw;
  height: 8vw;
  background-color: #c30d23;
  border-radius: 1vw;
  font-size: 4vw;
  color: white;
  text-align: center;
  line-height: 8vw;
}
.notmeal_specific {
  width: 97vw;
  height: 132vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
  margin: 0 auto;
}
.notmeal_specific_date {
  width: 95vw;
  height: 12vw;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 1vw;
  box-sizing: border-box;
}
.calendar_date {
  display: flex;
  align-items: center;
  font-size: 3vw;
  color: #353535;
}
.turn_img {
  width: 4vw;
  height: 5vw;
}
.turn {
  width: 15vw;
  height: 12vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
}
.specific_date {
  width: 95vw;
  margin: 5vw auto 0;
}
.specific_date_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #666666;
}
</style>