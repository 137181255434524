<template>
  <div class="password">
    <van-nav-bar title="修改支付密码" left-arrow @click-left="onClickLeft" />
    <div>
      <div class="password_all">
        <div class="password_name">初始密码</div>
        <div class="password_input">
          <input v-model="old" type="text" class="password_value" placeholder="请输入密码" />
        </div>
      </div>
      <div class="password_all">
        <div class="password_name">新密码</div>
        <div class="password_input">
          <input v-model="pay_pwd" type="text" class="password_value" placeholder="可输入英文+数字" />
        </div>
      </div>
      <div class="password_all">
        <div class="password_name">确认密码</div>
        <div class="password_input">
          <input v-model="pay_pwd2" type="text" class="password_value" placeholder="请输入密码" />
        </div>
      </div>
      <div @click="confirmClick" class="confim">确认</div>
    </div>
  </div>
</template>

<script>
import { memberSetPayPwd } from "../../api/all";
import { Toast } from 'vant';
export default {
  data() {
    return {
      pay_pwd: '',
      pay_pwd2: '',
      old: '',
    };
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    async confirmClick() {
      var type = 1;//1 修改密码(要传旧密码)   2设置密码
      var {pay_pwd,pay_pwd2,old} = this;
      if (!pay_pwd || !pay_pwd2 || !old) return Toast("请填写必要信息！");
      if (pay_pwd !== pay_pwd2) return Toast("两次输入的密码不一致");
      var res = await memberSetPayPwd({pay_pwd,type,old});
      if (res.status == '200') {
        this.$router.go(-1);
      }
      Toast(res.msg);
    }
  },
};
</script>

<style scoped>
.password {
  height: 100%;
  background-color: #f2f2f2;
}
.password_name {
  font-size: 3.467vw;
  color: #333333;
  text-align: left;
  padding-left: 2.933vw;
  margin: 4.4vw 0 2.4vw;
}
.password_input {
  width: 100%;
  height: 11.733vw;
  background-color: white;
}
.password_all {
  width: 97.333vw;
  margin: 0 auto;
}
.password_value {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  padding-left: 2.8vw;
  box-sizing: border-box;
}
.confim {
  width: 84.4vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  line-height: 13.467vw;
  text-align: center;
  color: white;
  margin: 6.533vw auto 0;
}
</style>