import Vue from 'vue'
import App from './App'
import Vant from 'vant';
import 'vant/lib/index.css';
import store from "./store";
import router from './router';
import base from './utils/base';
import './utils/convertOrderStatusToText'
import './utils/convertFinanceType'
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
Vue.prototype.$moment = moment;//赋值使用
Vue.prototype.$frontendurl = "https://chuniange.qupinmall.com/#/";
Vue.config.productionTip = false

Vue.use(Vant);
Vue.use(base);

// TODO
// var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjbGllbnQueGhhZG1pbiIsImF1ZCI6InNlcnZlci54aGFkbWluIiwiaWF0IjoxNjAxMzQ5MjQzLCJleHAiOjE2MzI4ODUyNDMsInVpZCI6MTd9.EBURHgbfvsT2TeLU8shKT0TfEflZUrDky8H41oQon18';
// localStorage.setItem("token",token);

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
