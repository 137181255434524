<template>
  <div v-if="financeInfo">
    <van-nav-bar title="账单明细" left-arrow @click-left="onClickLeft" />
    <div>
      <div class="bill_top">
        <div class="bill_card">
          <div class="bill_card_title">可用金额</div>
          <div class="bill_card_balance">￥{{financeInfo.remain}}</div>
          <div class="bill_card_recording">
            <div class="bill_consume">消费：￥{{financeInfo.totaluse}}</div>
            <div class="bill_recharge">充值：￥{{financeInfo.totalcharge}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bill_selecttime">
      <div class="bill_time" @click="selecttime">
        <div>{{currenttime}}</div>
        <img src="../../assets/bottom.png" class="bill_selecttime_icon" />
      </div>
      <div class="bill_all">消费￥{{financeInfo.monthuse}} 充值￥{{financeInfo.monthcharge}}</div>
    </div>
    <div>
      <div v-for="item in financeInfo.list" :key="item.id" class="singledata" @click="godetail(item)">
        <img src="../../assets/consumption.png" class="singledata_img" />
        <div class="singledata_right">
          <div class="singledata_right">
            <div class="singledata_right_type">
              <div>{{item.type| convertFinanceType}}</div>
              <div style="font-weight: bold;">{{item.type | convertFinancePulsMinus}}{{ item.type == 9 ? '' : item.price}}</div>
            </div>
            <div class="time-remain">
              <div class="singledata_right_time">{{item.create_time}}</div>
              <div class="money-remain">{{item.remain}}</div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- 选择时间 -->
    <van-action-sheet v-model="show">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="confimtime"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import { financeIndex } from "../../api/all";
export default {
  data() {
    return {
      show: false, //时间选择弹出
      minDate: new Date(2020, 0, 1), //最大时间
      maxDate: new Date(2025, 10, 1), //最小时间
      currentDate: new Date(),
      currenttime: "", //当前日期
      currenttimedash: "", //当前日期
      financeInfo: null,
    };
  },
  created() {
    //   获取第一个日期
    this.confimtime(this.currentDate);
  },
  methods: {
    async getFinanceInfo() {
      var res = await financeIndex({date: this.currenttimedash});
      if (res.status == '200') {
        this.financeInfo = res.data;
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
      
    },
    // 选择时间
    selecttime() {
      this.show = true;
    },
    // 跳转订单详情
    godetail(item) {
      this.$store.commit("saveFinanceItem",item);
      this.$router.push({
        name: "billdetail",
        query: {id: item.id}
      });
    },
    // 选择时间
    confimtime(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      this.currenttimedash = `${year}-${month}`
      this.currenttime = `${year}年${month}月`;
      this.show = false;
      this.getFinanceInfo();
    },
  },
};
</script>

<style scoped>
.bill_top {
  height: 40.8vw;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bill_card {
  width: 97.333vw;
  height: 35.467vw;
  border-radius: 1.333vw;
  background: url("~@/assets/billbc.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 2.933vw 0 2.667vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.bill_card_title {
  font-size: 4vw;
  color: white;
  text-align: left;
}
.bill_card_balance {
  font-size: 6.4vw;
  color: #fffefe;
  font-weight: bold;
}
.bill_card_recording {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3.733vw;
  color: #fffefe;
}
.bill_consume {
  position: relative;
  padding-left: 4.667vw;
}
.bill_consume::after {
  content: "";
  width: 3.467vw;
  height: 3.467vw;
  border: solid 0.4vw #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.bill_recharge {
  position: relative;
}
.bill_recharge::after {
  content: "";
  width: 3.467vw;
  height: 3.467vw;
  border: solid 0.4vw #ffffff;
  border-radius: 50%;
  position: absolute;
  left: -4.667vw;
  top: 50%;
  transform: translateY(-50%);
}
.bill_selecttime {
  height: 18.933vw;
  background-color: #fafafa;
  padding-left: 4.267vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.bill_selecttime_icon {
  width: 4.267vw;
  height: 2.4vw;
  margin-left: 3.733vw;
}
.bill_time {
  display: flex;
  align-items: center;
  font-size: 4.267vw;
  color: #000000;
}
.bill_all {
  font-size: 3.467vw;
  color: #666666;
}
.singledata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 17.6vw;
  padding-left: 4.267vw;
  box-sizing: border-box;
}
.singledata_right {
  width: 80vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 1px solid #e5e5e5;
}
.singledata_img {
  width: 10.667vw;
  height: 10.667vw;
}
.singledata_right_type, .time-remain {
  padding-right: 4vw;
  box-sizing: border-box;
  width: 100%;
  font-size: 4.267vw;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .time-remain {
  width: 100%;
  padding-right: 4vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
} */
.singledata_right_time, .money-remain {
  font-size: 3.467vw;
  color: #666666;
}
</style>