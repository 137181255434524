<template>
  <div>
      <div v-if="memberInfo" class="allmeal">
            <div class="allmeal_morning">
              <div class="allmeal_morning_food">
                午餐默认数量：A餐辣菜
                <span>{{default_meal_info.am_a_default}}份</span>；B餐不加辣菜
                <span>{{default_meal_info.am_b_default}}份</span>
              </div>
              <div class="allmeal_open" @click="setting(1)">
                <div>{{default_meal_info.am_default ? "已开启" : "已关闭"}}</div>
                <img src="../../assets/right.png" class="allmeal_right" />
              </div>
            </div>
            <div class="allmeal_morning">
              <div class="allmeal_morning_food">
                晚餐默认数量：A餐辣菜
                <span>{{default_meal_info.pm_a_default}}份</span>；B餐不加辣菜
                <span>{{default_meal_info.pm_b_default}}份</span>
              </div>
              <div class="allmeal_open"  @click="setting(2)">
                <div>{{default_meal_info.pm_default ? "已开启" : "已关闭"}}</div>
                <img src="../../assets/right.png" class="allmeal_right" />
              </div>
            </div>
            <div class="allmeal_tips">
              <div v-if="memberInfo.type == 1">截单时间：午餐{{memberInfo.member_am_deadline}}，晚餐{{memberInfo.member_pm_deadline}}；各位用餐愉快哦~</div>
              <div v-else>截单时间：午餐{{memberInfo.admin_am_deadline}}，晚餐{{memberInfo.admin_pm_deadline}}；各位用餐愉快哦~</div>
              <div>默认订餐于早上06:00 自动下单</div>
            </div>
          </div>
          <!-- 午餐日期 -->
          <div v-if="isLunchStopMeal">
            <div class="foodtime">
              <img src="../../assets/calendar.png" class="calendar" />
              <div>{{today}}</div>
            </div>
            <div class="lunch">
              <div class="lunch_top">
                <img src="../../assets/morning.png" class="morning_img" />
                <div>午餐</div>
              </div>
              <div style="height: 30vw;font-size: 40px;line-height: 30vw;">
                {{tips.content}}
              </div>
              <div class="calculatetotal">
                <div class="calculatetotal_num">共{{countMealTotalNum("am")}}份</div>
                <div class="calculatetotal_bto_disabled">{{tips.btn}}</div>
              </div>
            </div>
          </div>


          <div v-if="!isLunchStopMeal && todayMealList.am && todayMealList.am.length > 0">
            <div class="foodtime">
              <img src="../../assets/calendar.png" class="calendar" />
              <div>{{today}}</div>
            </div>
            <div class="lunch">
              <div class="lunch_top">
                <img src="../../assets/morning.png" class="morning_img" />
                <div>午餐</div>
              </div>
              <div v-for="item in todayMealList.am" :key="item.id">
                <div v-if="item.id" class="product_single">
                  <img
                    :src="item.pic"
                    class="product_img"
                  />
                  <div class="product_info">
                    <div class="meal-title">{{item | convertMealToText}}</div>
                    <div class="stepper-box">
                    <van-stepper min="0" :disabled="item.isDisabled" v-model="item.count" />

                    </div>
                  </div>
                </div>
              </div>
              <div class="calculatetotal">
                <div class="calculatetotal_num">共{{countMealTotalNum("am")}}份</div>
                <div v-if="todayMealList.am[0].order_status == 6" class="calculatetotal_bto_disabled">已完成</div>
                <div v-else-if="todayMealList.am[0].order_status == 5" @click="routeToEdit(todayMealList.am)" class="calculatetotal_bto">去收货</div>
                <div v-else-if="isDisableAddOrder(true) || isOrderSend(true)" class="calculatetotal_bto_disabled">已截单</div>
                <div v-else @click="popupConfirmOrder(1)" class="calculatetotal_bto">{{todayMealList.am[0] && todayMealList.am[0].isDisabled ? '修改' : '去结算'}}</div>
              </div>
            </div>
          </div>

          
          <!-- 晚餐日期 -->
          <div v-if="isDinnerStopMeal">
            <div class="foodtime">
              <img src="../../assets/calendar.png" class="calendar" />
              <div>{{today}}</div>
            </div>
            <div class="lunch">
              <div class="lunch_top">
                <img src="../../assets/dinner.png" class="morning_img" />
                <div>晚餐</div>
              </div>
              <div style="height: 30vw;font-size: 40px;line-height: 30vw;">
                {{tips.content}}
              </div>
              <div class="calculatetotal">
                <div class="calculatetotal_num">共{{countMealTotalNum("pm")}}份</div>
                <div class="calculatetotal_bto_disabled">{{tips.btn}}</div>
              </div>
            </div>
          </div>
          <!-- 晚餐日期 -->
          <div v-if="!isDinnerStopMeal && todayMealList.pm && todayMealList.pm.length > 0">
            <div class="foodtime">
              <img src="../../assets/calendar.png" class="calendar" />
              <div>{{today}}</div>
            </div>
            <div class="lunch">
              <div class="lunch_top">
                <img src="../../assets/dinner.png" class="morning_img" />
                <div>晚餐</div>
              </div>
              <div v-for="item in todayMealList.pm" :key="item.id">
                <div v-if="item.id"  class="product_single">
                  <img
                    :src="item.pic"
                    class="product_img"
                  />
                  <div class="product_info">
                    <div class="meal-title">{{item | convertMealToText}}</div>
                    <div class="stepper-box">
                    <van-stepper min="0" :disabled="item.isDisabled" v-model="item.count" />

                    </div>
                  </div>
                </div>
              </div>
              <div class="calculatetotal">
                <div class="calculatetotal_num">共{{countMealTotalNum("pm")}}份</div>
                <div v-if="todayMealList.pm[0].order_status == 6" class="calculatetotal_bto_disabled">已完成</div>
                <div v-else-if="todayMealList.pm[0].order_status == 5" @click="routeToEdit(todayMealList.pm)" class="calculatetotal_bto">去收货</div>
                <div v-else-if="isDisableAddOrder(false) || isOrderSend(false)" class="calculatetotal_bto_disabled">已截单</div>
                <div v-else @click="popupConfirmOrder(2)" class="calculatetotal_bto">{{todayMealList.pm[0] && todayMealList.pm[0].isDisabled ? '修改' : '去结算'}}</div>
              </div>
            </div>
          </div>
  </div>
</template>

<script>
import { getMenu,orderAdd } from "../../api/all";
import { Toast,Dialog } from 'vant';
import { mapGetters } from 'vuex'
export default {
  async created() {
    await this.$store.dispatch("getMemberInfo");
    this.today = this.$moment().format("YYYY/MM/DD");
    this.getTodayMeal();
  },
  data() {
    return {
      today: "",
      todayMealList: {
        am: [],
        pm: []
      },
      orderType: 1,
      isLunchStopMeal: false,
      isDinnerStopMeal: false,
      tips: {
        content: '今日停餐',
        btn: '已停餐',
      }
    };
  },
  props: {
      tabsindex: Number,//0： 企业企业 ；1： 企业个人 ； 2： 个人个人
  },
  filters: {
    convertMealToText: function (meal) {
      var mealType = meal.type == 1 ? 'A餐辣餐：\n' : 'B餐不辣餐：\n';
      return mealType + meal.full;
    }
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
    default_meal_info() {// 自动点餐相关信息 企业/个人
      var res = {
        am_a_default: 0,
        am_b_default: 0,
        pm_a_default: 0,
        pm_b_default: 0,
        am_default: 0,
        pm_default: 0,
      };
      switch (this.tabsindex) {
        case 0:
          res.am_a_default = this.memberInfo.inc_am_a_default || 0;
          res.am_b_default = this.memberInfo.inc_am_b_default || 0;
          res.pm_a_default = this.memberInfo.inc_pm_a_default || 0;
          res.pm_b_default = this.memberInfo.inc_pm_b_default || 0;
          res.am_default = this.memberInfo.inc_am_is_default || 0;
          res.pm_default = this.memberInfo.inc_pm_is_default || 0;
          break;
        case 1:
        case 2:
          res.am_a_default = this.memberInfo.am_a_default || 0;
          res.am_b_default = this.memberInfo.am_b_default || 0;
          res.pm_a_default = this.memberInfo.pm_a_default || 0;
          res.pm_b_default = this.memberInfo.pm_b_default || 0;
          res.am_default = this.memberInfo.am_is_default || 0;
          res.pm_default = this.memberInfo.pm_is_default || 0;
          break;
      
        default:
          break;
      }
      return res;
    },
    countMealTotalNum() {// 点餐份数
      return (mealTime)=> {
        var mealList = this.todayMealList[mealTime];
        if (mealList == null) {
          return 0;
        }
        var res = 0;
        mealList.forEach(item=> res += item.count);
        return res;
      }
    },
    isDisableAddOrder() {
      return (isAm) => {
        this.today = this.$moment().format("YYYY-MM-DD");
        var temp = this.$moment().format("YYYY/MM/DD");
        var am_deadline;
        var pm_deadline;
        if (this.memberInfo.type == 1) {
          am_deadline = this.memberInfo.member_am_deadline;
          pm_deadline = this.memberInfo.member_pm_deadline;
        } else {
          am_deadline = this.memberInfo.admin_am_deadline;
          pm_deadline = this.memberInfo.admin_pm_deadline;
        }
        if (isAm) {
          temp = temp + ' ' + am_deadline;
        }else {
          temp = temp + ' ' + pm_deadline;
        }
        var deadlineTimeStamp = new Date(temp).getTime();
        var nowTimeStamp = Date.now();
        
        if (nowTimeStamp < deadlineTimeStamp) {
          return false;
        }else {
          return true;
        }
      }
    },
    isOrderSend() {
      return (isAm) => {
        var {am,pm} = this.todayMealList;
        var tempMeal;
        if(isAm) {
          tempMeal = am[0];
        }else {
          tempMeal = pm[0];
        }
        if (tempMeal.inc_order_status > 3) {
          return true;
        }else {
          return false;
        }
      }
    },

  },
  methods: {
    dealTime(isAm) {
      // <div v-if="memberInfo.type == 1">截单时间：午餐{{memberInfo.member_am_deadline}}，晚餐{{memberInfo.member_pm_deadline}}；各位用餐愉快哦~</div>
      // <div v-else>截单时间：午餐{{memberInfo.admin_am_deadline}}，晚餐{{memberInfo.admin_pm_deadline}}；各位用餐愉快哦~</div>
      this.today = this.$moment().format("YYYY-MM-DD");
      var temp = this.$moment().format("YYYY/MM/DD");
      var am_deadline;
      var pm_deadline;
      if (this.memberInfo.type == 1) {
        am_deadline = this.memberInfo.member_am_deadline;
        pm_deadline = this.memberInfo.member_pm_deadline;
      } else {
        am_deadline = this.memberInfo.admin_am_deadline;
        pm_deadline = this.memberInfo.admin_pm_deadline;
      }
      if (isAm) {
        temp = temp + ' ' + am_deadline;
      }else {
        temp = temp + ' ' + pm_deadline;
      }
      var deadlineTimeStamp = new Date(temp).getTime();
      var nowTimeStamp = Date.now();
      if (nowTimeStamp < deadlineTimeStamp) {
        return true;
      }else {
        // Toast("已过截单时间");
        return false;
      }
    },
    routeToEdit(mealist) {
      this.$router.push({
          name: "edit",
          query: {order_num: mealist[0].order_num}
        });
    },
    // 去结算
    popupConfirmOrder(orderType) {
      this.orderType = orderType;
      var {am_is_default,pm_is_default,inc_am_is_default,inc_pm_is_default} = this.memberInfo;
      if (orderType == '1' && this.tabsindex != 0 && inc_am_is_default) {
        return Toast("公司已开启午餐默认点餐，个人无法点餐");
      }
      if (orderType == '2' && this.tabsindex != 0 && inc_pm_is_default) {
        return Toast("公司已开启晚餐默认点餐，个人无法点餐");
      }
      if (orderType == '1' && this.tabsindex != 0 && am_is_default) {
        return Toast("您已开启午餐默认点餐，无法单独点餐");
      }
      if (orderType == '2' && this.tabsindex != 0 && pm_is_default) {
        return Toast("您已开启晚餐默认点餐，无法单独点餐");
      }
      if (orderType == '1' && this.tabsindex == 0 && inc_am_is_default) {
        return Toast("公司已开启午餐默认点餐");
      }
      if (orderType == '2' && this.tabsindex == 0 && inc_pm_is_default) {
        return Toast("公司已开启晚餐默认点餐");
      }
      var mealist = this.orderType == 1 ? this.todayMealList["am"] : this.todayMealList["pm"];
      if (mealist[0].isDisabled) {
        this.$router.push({
          name: "edit",
          query: {order_num: mealist[0].order_num}
        });
        return;
      }else {
        var isAllZero = true;
        for (let index = 0; index < mealist.length; index++) {
          if (mealist[index].count > 0) {
            isAllZero = false;
            break;
          }
        }
        if (isAllZero) {
          return Toast("点餐数量不能为0");
        }
      }
      
      Dialog.confirm({
        title: '标题',
        message: '是否提交订单',
        beforeClose: this.confirmOrder,
      });
    },
    // 结算弹窗确认
    async confirmOrder(action, done) {
      if (action === 'confirm') {
        var args = {
          ameal_id: null,
          ameal_num: null,
          bmeal_id: null,
          bmeal_num: null,
          type: this.orderType,
          eat_time: this.today,
        }
        var mealist = this.orderType == 1 ? this.todayMealList["am"] : this.todayMealList["pm"];
        mealist.forEach(meal=> {
          if (meal.type == 1) {
            args.ameal_id = meal.id;
            args.ameal_num = meal.count;
          }
          if (meal.type == 2) {
            args.bmeal_id = meal.id;
            args.bmeal_num = meal.count;
          }
        })
        var res = await orderAdd({data: args});
        done();
        
        this.getTodayMeal();
        if (res.status == '200' && this.memberInfo.type == 3) {
          this.$router.push({name: 'edit',query: {order_num: res.data}});
        }
        setTimeout(() => {
          Toast(res.msg);
        }, 600);
        
      } else {
        done();
      }
    },
    // 今日菜单
    async getTodayMeal() {
      var res = await getMenu({date: this.today});
      if (res.status == '200') {
        var data = res.data;
        if (!data.am || data.am.length == 0) {
          this.isLunchStopMeal = true;
          this.tips = {
            content: '暂未安排',
        btn: '暂未安排',
          };
        }
        if (!data.pm || data.pm.length == 0 ) {
          this.isDinnerStopMeal = true;
          this.tips = {
            content: '暂未安排',
        btn: '暂未安排',
          };
        }
        if (!this.isLunchStopMeal) {
          data.am.forEach(item => {
          if (item.hasOwnProperty("num")) {
            item["count"] = item.num;
            item["isDisabled"] = true;
          }else {
            item["count"] = 0;
          }
        });
        }
        if (!this.isDinnerStopMeal) {
          data.pm.forEach(item => {
          if (item.hasOwnProperty("num")) {
            item["count"] = item.num;
            item["isDisabled"] = true;
          }else {
            item["count"] = 0;
          }
        });
        }
        this.todayMealList = data
      }else {
        this.isLunchStopMeal = true;
        this.isDinnerStopMeal = true;
        Toast(res.msg)
      }
    },
    // 设置默认
    setting(mealType) {
      var tabsindex = this.tabsindex;
      this.$router.push({
        name: "Default",
        query: {mealType,tabsindex}
      });
    },
  },
};
</script>

<style scoped>
.allmeal {
  width: 97vw;
  height: 45vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
  margin: 1vw auto 0;
}
.allmeal_morning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12vw;
  width: 92vw;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
}
.allmeal_morning_food {
  font-size: 3vw;
  color: #666666;
  font-weight: bold;
  flex: 1;
  text-align: left;
}
.allmeal_morning_food span {
  color: #ff4747;
}
.allmeal_right {
  width: 2vw;
  height: 4vw;
  margin-left: 1vw;
}
.allmeal_open {
  display: flex;
  margin-left: 8px;
  align-items: center;
  font-size: 3vw;
  color: #939393;
}
.allmeal_tips {
  font-size: 3vw;
  color: #ff4747;
  font-weight: bold;
  height: 20vw;
  display: flex;
  padding: 0 3vw;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.foodtime {
  display: flex;
  align-items: center;
  padding-left: 4vw;
  margin: 2vw 0;
  font-size: 3vw;
  color: #666666;
  height: 9vw;
  background: #f1f1f1;
}
.calendar {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
}
.lunch {
  width: 97vw;
  margin: 0 auto 3vw;
}
.lunch_top {
  display: flex;
  align-items: center;
  height: 9vw;
  background-color: #c30d23;
  color: white;
  box-sizing: border-box;
  padding-left: 3vw;
  font-size: 4vw;
  border-radius: 1vw 1vw 0 0;
}
.morning_img {
  width: 7vw;
  height: 7vw;
  margin: 2vw;
}
.product_img {
  width: 23vw;
  height: 23vw;
  border-radius: 1vw;
}
.product_single {
  display: flex;
  width: 91vw;
  margin: 3vw auto 0;
  height: 23vw;
  align-items: center;
}
.product_info {
  display: flex;
  flex: 1;
  margin-left: 12px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.stepper-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.meal-title {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}
.calculatetotal {
  margin-top: 4vw;
  height: 12vw;
  background-color: #c30d23;
  border-radius: 0vw 0vw 1vw 1vw;
  display: flex;
  justify-content: space-between;
}
.calculatetotal_bto,.calculatetotal_bto_disabled {
  width: 33vw;
  height: 12vw;
  background-color: #99091a;
  line-height: 12vw;
  font-size: 4vw;
  color: white;
  text-align: center;
}
.calculatetotal_bto_disabled {
  /* background-color: rgba(0, 0, 0, 0.6); */
  color: #c30d23;
  background-color: #f2f3f5;
}
.calculatetotal_num {
  line-height: 12vw;
  font-size: 4vw;
  color: white;
  padding-left: 3vw;
}
</style>