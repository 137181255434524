<template>
  <div class="mine">
    <van-nav-bar title="我的" />
    <div class="mine_card">
      <img v-if="memberInfo.wx_head" :src="memberInfo.wx_head" class="mine_img" />
      <img v-else src="../../assets/logo.png" class="mine_img" />
      <div class="mine_info">
        <div>{{memberInfo.username}}，{{memberInfo.inc_name}}</div>
        <div v-if="memberInfo.type == 3" class="mine_balance">
          余额
          <span>{{memberInfo.remain}}元</span>
        </div>
        <div v-if="memberInfo.type == 1" class="mine_balance">
          {{memberInfo.department}}
        </div>
        <div v-if="memberInfo.type == 1" class="mine_balance">
          {{memberInfo.phone}}
        </div>
      </div>
      <img v-if="memberInfo.type == 1" src="../../assets/edit.png" class="company_single_edit" @click="editnotes" />
    </div>
    <div v-if="memberInfo.type == 3">
      <div class="mine_service" @click="gobill">
        <div class="mine_service_left">
          <img src="../../assets/icon/my1.png" class="mine_service_icon" />
          <div>账单明细</div>
        </div>
        <img src="../../assets/right.png" class="mine_service_right" />
      </div>
      <div class="mine_service" @click="gocompany">
        <div class="mine_service_left">
          <img src="../../assets/icon/my2.png" class="mine_service_icon" />
          <div>公司人员</div>
        </div>
        <img src="../../assets/right.png" class="mine_service_right" />
      </div>
      <!-- <div class="mine_service">
        <div class="mine_service_left">
          <img src="../../assets/icon/my3.png" class="mine_service_icon" />
          <div>点餐记录</div>
        </div>
        <img src="../../assets/right.png" class="mine_service_right" />
      </div> -->
      <div class="mine_service" @click="gopwd">
        <div class="mine_service_left">
          <img src="../../assets/icon/my4.png" class="mine_service_icon" />
          <div>更改支付密码</div>
        </div>
        <img src="../../assets/right.png" class="mine_service_right" />
      </div>
    </div>
    <div class="logout" @click="logout">退出登录</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {memberLogout} from '../../api/all'
export default {
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
  methods: {
    async logout() {
      await memberLogout();
      if (this.memberInfo.type == 1) {
        localStorage.clear();
        this.$router.replace({ path: "/employeeInitInfo" });
      }else {
        localStorage.clear();
        window.location.href = `${window.location.origin}/#/?role=${this.memberInfo.type}`;
      }
    },
    // 跳转修改备注
    editnotes() {
      this.$router.push({
        name: "editnotes",
        query: this.memberInfo
      });
    },
    // 账单明细
    gobill() {
      this.$router.push({
        name: "bill",
      });
    },
    // 更改支付密码
    gopwd() {
      if (!this.memberInfo.has_paypwd) {
        this.$router.push({
          name: "paymentpwd",
        });
      } else {
        this.$router.push({
          name: "editpwd",
        });
      }
    },
    // 公司人员
    gocompany() {
      this.$router.push({
        name: "companypeople",
      });
    },
  },
};
</script>

<style scoped>
.company_single_edit {
  width: 4.533vw;
  height: 4.533vw;
}
.mine {
  height: 100%;
  background-color: #f2f2f2;
}
.mine_card {
  width: 100%;
  height: 30.4vw;
  margin: 2.667vw 0 4vw;
  background: url("~@/assets/minebc.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 0 4.4vw;
  box-sizing: border-box;
}
.mine_img {
  width: 16vw;
  height: 16vw;
  border-radius: 50%;
}
.mine_info {
  flex: 1;
  margin-left: 5.2vw;
  font-size: 3.467vw;
  color: white;
  height: 16vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.mine_balance span {
  font-size: 4.533vw;
  font-weight: bold;
}
.mine_service {
  height: 11.333vw;
  background-color: #ffffff;
  font-size: 3.467vw;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.867vw 0 1.867vw;
  box-sizing: border-box;
  margin-bottom: 2.667vw;
}
.mine_service_icon {
  width: 5.333vw;
  height: 5.333vw;
  margin-right: 2.533vw;
}
.mine_service_left {
  display: flex;
  align-items: center;
}
.mine_service_right {
  width: 2.4vw;
  height: 4.133vw;
}
.logout {
  width: 84.4vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  text-align: center;
  line-height: 13.467vw;
  color: white;
  margin: 7.867vw auto 0;
}
</style>