import Vue from 'vue'


// 过滤器
Vue.filter('convertFinanceType', function (financeType) {
  switch (financeType) {
      case 1:
      return '充值';
      case 2:
      return '消费';
      case 3:
      return '加单';
      case 4:
      return '减单';
      case 5:
      return '清零';
      case 6:
      return '撤销充值';
      case 7:
      return '补单';
      case 8:
      return '退款';
      case 9:
      return '已用额度清零';
      default:
      return '';
  }
});

Vue.filter('convertFinancePulsMinus', function (financeType) {
    switch (financeType) {
        case 1:
        return '+';
        case 2:
        return '-';
        case 3:
        return '-';
        case 4:
        return '+';
        case 5:
        return '';
        case 7:
        return '-';
        case 8:
        return '+';
        default:
        return '';
        break;
    }
  });
