<template>
  <div>
    <van-nav-bar
      title="回收统计"
      left-text=""
      right-text=""
      :border="false"
    />
    <div class="info_back">
      <div class="info_cont">
        <div class="info_top">
          <span>{{ today }}</span>
          <div @click="changeType" class="toggle-halfday">
              <p>{{type == 1 ? '上午' : '下午'}}</p>
              <img src="@/assets/toggle-halfday.png" alt="">
          </div>
        </div>
        <van-cell-group>
        <div class="info_item">
          <div class="info_title">公司名称</div>
          <div class="enterprise_name">{{ routeParams.inc_name }}</div>
        </div>
          <div class="info_item">
            <div class="info_title">饭箱数量</div>
            <div class="info_right">
              <!-- <van-field v-model="data.bucket_require_num" placeholder="请输入数量" type="number" class="data_input" /> -->
              <input type="text" v-model="data.case_require_num" placeholder="请输入数量" class="login_frame_input" />
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">汤桶数量</div>
            <div class="info_right">
              <!-- <van-field v-model="data.case_require_num" placeholder="请输入数量" type="number" class="data_input" /> -->
              <input type="text" v-model="data.bucket_require_num" placeholder="请输入数量" class="login_frame_input" />
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">红餐数量</div>
            <div class="info_right red_text">
              <!-- <van-field v-model="data.ameal_require_num" placeholder="请输入数量" type="number" class="data_input" /> -->
              <input type="text" v-model="data.ameal_require_num" placeholder="请输入数量" class="login_frame_input" />
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">蓝餐数量</div>
            <div class="info_right blue_text">
              <!-- <van-field v-model="data.bmeal_require_num" placeholder="请输入数量" type="number" class="data_input" /> -->
              <input type="text" v-model="data.bmeal_require_num" placeholder="请输入数量" class="login_frame_input" />
            </div>
          </div>
        </van-cell-group>
        <div class="sure_back">
          <div class="sure_btn" @click="sureSubmit()">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { recoveryUpdate } from "../../api/all";
export default {
  name: 'SendRecovery',
  data() {
    return {
      data: {
        'case_require_num': 0,
        'bucket_require_num': 0,
        'ameal_require_num': 0,
        'bmeal_require_num': 0,
      },
      today: null,
      type: 1,
      info: {},
      list: [],
      routeParams: null,
    };
  },
  created() {
    console.log(this.$route.query,555);
    this.routeParams = this.$route.query;
    this.changeDataDefault();
    this.today = this.$moment().format("YYYY-MM-DD");
  },
  watch: {
    type: function() {
      this.changeDataDefault();
    }
  },
  methods: {
    changeType() {
      if (this.type == 1) {
        this.type = 2;
      }else {
        this.type = 1;
      }
      this.$nextTick(()=> {
        this.changeDataDefault();
      })
      
    },
    changeDataDefault() {
      if (this.type == 1) {
        var {am_ameal_actual_num,am_bmeal_actual_num,am_bucket_actual_num,am_case_actual_num, am_ameal_require_num,am_bmeal_require_num,am_bucket_require_num,am_case_require_num
        } = this.routeParams;
        if (am_ameal_require_num != null) {
          this.data = {
            'case_require_num': am_case_require_num||0,
            'bucket_require_num': am_bucket_require_num||0,
            'ameal_require_num': am_ameal_require_num||0,
            'bmeal_require_num': am_bmeal_require_num||0,
          }
        }else {
          this.data = {
            'case_require_num': 0,
            'bucket_require_num': 0,
            'ameal_require_num': 0,
            'bmeal_require_num': 0,
          }
        }
      }else {
        var {pm_ameal_actual_num,pm_bmeal_actual_num,pm_bucket_actual_num,pm_case_actual_num, pm_ameal_require_num,pm_bmeal_require_num,pm_bucket_require_num,pm_case_require_num} = this.routeParams;
        if (pm_ameal_require_num != null) {
          this.data = {
            'case_require_num': pm_case_require_num,
            'bucket_require_num': pm_bucket_require_num,
            'ameal_require_num': pm_ameal_require_num,
            'bmeal_require_num': pm_bmeal_require_num,
          }
        }else {
          this.data = {
            'case_require_num': 0,
            'bucket_require_num': 0,
            'ameal_require_num': 0,
            'bmeal_require_num': 0,
          }
        }
      }
    },
    async sureSubmit() {
      var recycleInfo = this.type == 1 ? this.routeParams.am_ameal_require_num : this.routeParams.pm_ameal_require_num;
      if (recycleInfo == null) {
        return Toast("暂无回收需求");
      }
      // var { order_id, time, inc_id, driver_id } = recycleInfo;
      // this.data = Object.assign(this.data,{ order_id, time, inc_id, driver_id });
      var { id } = this.routeParams;
      var args = {id};
      var {case_require_num,bucket_require_num,ameal_require_num,bmeal_require_num} = this.data;
      if (this.type == 1) {
        args['am_case_actual_num'] = case_require_num;
        args['am_bucket_actual_num'] = bucket_require_num;
        args['am_ameal_actual_num'] = ameal_require_num;
        args['am_bmeal_actual_num'] = bmeal_require_num;
      }else {
        args['pm_case_actual_num'] = case_require_num;
        args['pm_bucket_actual_num'] = bucket_require_num;
        args['pm_ameal_actual_num'] = ameal_require_num;
        args['pm_bmeal_actual_num'] = bmeal_require_num;
      }
      var res = await recoveryUpdate(args);
      if (res.status == '200') {
        this.$router.go(-1);
      }
      Toast(res.msg);
    }
  }
}
</script>
<style scoped>
.login_frame_input {
  border: 1px solid #ccc;
  flex: 1;
  padding: 0 2vw;
  line-height: 10vw;
  outline: none;
}
  .info_back{
    padding: 3vw 1vw;
  }
  .info_cont{
    background-color: #ffffff;
    border-radius: 1vw;
    border: 1px solid #e5e5e5;
  }
  .info_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9vw;
    padding: 0 3vw;
    border-bottom: 1px solid #e5e5e5;
  }
  .info_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 17vw;
    padding: 1vw 0;
  }
  .info_title{
    display: flex;
    font-size: 4vw;
    align-items: center;
    justify-content: center;
    color: #333333;
    width: 21vw;
    height: 100%;
  }
  .enterprise_name{
    color: #000000;
    font-size: 4vw;
    width: 73vw;
    text-align: left;
  }
  .data_input{
    border-radius: 1vw;
    border: 1px solid #cccccc;
    width: 100%;
    height: 12vw;
    font-size: 4vw;
  }
  .info_right{
    display: flex;
    width: 79vw;
    height: 100%;
    align-items: center;
    padding: 0 2vw;
  }
  .red_text >>> .van-field__control{
    color: #ff0020;
  }
  .blue_text >>> .van-field__control{
    color: #0084ff;
  }
  .sure_btn{
    width: 84vw;
    height: 13vw;
    background-color: #c30d23;
    border-radius: 1vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sure_back{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw 0;
    margin-top: 4vw;
  }
  .select_type{
    border: 0;
    color: #ef364d;
    font-size: 4vw;
    outline: none;
  }
  .toggle-halfday {
    display: flex!important;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    
    /* font-family: PingFang-SC-Bold; */
    font-weight: bold;
    font-size: 14px;
    color: #ef364d;
  }
  .toggle-halfday img {
    display: block;
    width: 9px;
    height: 17px;
    margin-left: 6px;
  }
</style>