<template>
  <div class="login">
    <div class="login_back">
      <img src="../assets/login/bc.png" class="login_backimg" />
    </div>
    <h3 style="text-align: center;">{{loginArgs.inc_name}}</h3>
    <div>
      <div class="login_frame">
        <div class="login_frame_title">姓名</div>
        <input type="text" v-model="loginArgs.nickname" placeholder="请输入姓名" class="login_frame_input" />
      </div>
      <div class="login_frame">
        <div class="login_frame_title">部门</div>
        <input type="text" v-model="loginArgs.department" placeholder="请输入部门名称" class="login_frame_input" />
      </div>
      <div class="login_frame">
        <div class="login_frame_title">电话</div>
        <input type="text" v-model="loginArgs.phone" placeholder="请输入手机号码" class="login_frame_input" />
      </div>
    </div>
    <div class="login_bto" @click="login">登录</div>
  </div>
</template>

<script>
// http://chuniange.qupinmall.com/?#/employeeInitInfo
import { Toast } from 'vant';
import { memberWxlogin } from "../api/all";
export default {
  name: "employeeInitInfo",
  data() {
    return {
      loginArgs: {
        nickname: '',
        department:'',
        phone: '',
        inc_id: null,
        inc_name: "",
      },
      
    };
  },
  created() {
    var code = this.$getUrlKey('code');
    // var code = this.$getCodeFromHref();
    if (code) {
      // this.loginArgs = JSON.parse(localStorage.getItem("employeeInitInfos"));
      this.getOpenId();
    }else {
      this.enterPage();
    }
    
  },
  methods: {
    async enterPage() {
      var {inc_id,inc_name} = this.$route.query;
      if (!inc_id) {
        var oldRole = localStorage.getItem("role");
        if (oldRole && oldRole != 1) {
          localStorage.clear();
        }

        var token = localStorage.getItem("token");
        if (token) {
          await this.$store.dispatch("getMemberInfo");
          window.location.href = `${window.location.origin}/#/meal`;
          return;
        }
      }else {
        this.loginArgs.inc_id = inc_id;
        this.loginArgs.inc_name = inc_name;
        localStorage.setItem("employeeInitInfos",JSON.stringify(this.loginArgs));
      }
      this.goWeChatAuth();
    },
    goWeChatAuth() {
      // if(navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc68be605ecabdb84&redirect_uri=${encodeURIComponent(
        `${this.$frontendurl}employeeInitInfo`
      )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      // }
    },
     async doMemberWxlogin(args) {
      Toast.loading();
      var res = await memberWxlogin(args);
      if (res.status == '200') {
        localStorage.setItem("token",res.token);
        localStorage.setItem("userinfo",JSON.stringify(res.data));
        localStorage.removeItem("employeeInitInfos");
        await this.$store.dispatch("getMemberInfo");
        window.location.href = `${window.location.origin}/#/meal`;
      }
      Toast.clear();
      Toast(res.msg);
    },
    async getOpenId() {
      // var code = this.$getCodeFromHref();
      var code = this.$getUrlKey('code');
      if (code) {
        var employeeInitInfos = JSON.parse(localStorage.getItem("employeeInitInfos"));
        if (!employeeInitInfos) {
          employeeInitInfos = {};
          employeeInitInfos["code"] = code;
          employeeInitInfos["type"] = 2;
          this.doMemberWxlogin(employeeInitInfos);
          return;
        }
        employeeInitInfos["code"] = code;
        employeeInitInfos["type"] = 1;
        this.loginArgs = employeeInitInfos;
      }
    },
    // 登陆跳转 http://192.168.1.22:8080/?code=041bZlFa1E2ryz0bl7Ga1WqTv23bZlF7&state=STATE#/meal
    async login() {
      var {loginArgs} = this;
      if (loginArgs.inc_id==null) return Toast("请联系管理员绑定");
      if (loginArgs.nickname.length == 0 || loginArgs.department.length == 0 || loginArgs.phone.length == 0) {
        Toast("请填写完整信息！");
        return;
      }
      this.doMemberWxlogin(loginArgs);
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
}
.login_back {
  width: 100%;
  height: auto;
}
.login_backimg {
  width: 100%;
  height: 100%;
}
.login_frame {
  display: flex;
  align-items: center;
  width: 93.2vw;
  height: 11.733vw;
  background: white;
  border: 1px solid #cccccc;
  padding: 0 2.933vw;
  box-sizing: border-box;
  margin: 0 auto 4.267vw;
}
.login_frame_input {
  border: none;
  outline: none;
}
.login_frame_title {
  margin-right: 4.4vw;
  font-size: 4.267vw;
}
.login_bto {
  width: 84vw;
  height: 13vw;
  background-color: #c30d23;
  border-radius: 1vw;
  text-align: center;
  line-height: 13vw;
  color: white;
  font-size: 5vw;
  margin: 5vw auto 0;
}
</style>
