<template>
<div style="background-color: #f2f2f2;">
  <div @click="editorder(item)" v-for="item in orderList" :key="item.id" class="order_list">
          <div class="order_list_top">
            <div class="order_list_ordernum">用餐时间：{{item.eat_time + item.type}}</div>
            <!-- <div class="order_list_ordernum">订单号：{{item.order_num}}</div> -->
            <div class="order_liststatus">{{item.order_status | convertOrderStatusToText}}</div>
          </div>

          <div class="order_list_center">
            <img
              :src="item.ameal_pic"
              class="order_list_img"
            />
            <div class="order_list_guige">
              <div class="order_list_name">A餐{{item.ameal_full}}</div>
              <div class="order_list_guige_bottom">
                <div class="order_list_price">￥{{item.single_price}}</div>
                <div class="order_list_num">x{{item.ameal_num}}</div>
              </div>
            </div>
          </div>

          <div class="order_list_center">
            <img
              :src="item.bmeal_pic"
              class="order_list_img"
            />
            <div class="order_list_guige">
              <div class="order_list_name">B餐{{item.bmeal_full}}</div>
              <div class="order_list_guige_bottom">
                <div class="order_list_price">￥{{item.single_price}}</div>
                <div class="order_list_num">x{{item.bmeal_num}}</div>
              </div>
            </div>
          </div>

          <div class="order_list_bottom">
            <div class="order_list_total">
              共{{item.ameal_num + item.bmeal_num}}份订餐，合计
              <span>￥{{item.total_price}}</span>
            </div>
            <div v-if="memberInfo.type == 3 && item.order_status != 4" class="order_list_bto" @click.stop="editorder(item,true)">{{item.order_status | generateOrderOperationBtns}}</div>
            <div v-if="(item.order_status > 0 && item.order_status < 4) " class="order_list_bto" @click.stop="deleteOrderClick(item)">取消订单</div>
            <div v-if="memberInfo.type == 1 && (item.order_status == 1 || item.order_status == 2) " class="order_list_bto" @click.stop="editorder(item,true)">修改订单</div>
          </div>
        </div>
        <!-- 输入密码 -->
        <van-dialog v-model="inputPayPwd" title="支付密码" show-cancel-button :before-close="confirmDeleteOrder">
          <!-- 输入密码 -->
          <van-field v-model="pay_pwd" placeholder="请输入支付密码" type="password"/>
        </van-dialog>
</div>
  
</template>

<script>
import { cancelOrder,orderDetailUpdate } from "../../api/all";
import { mapGetters } from 'vuex';
import {Dialog,Toast} from 'vant';
export default {
  data() {
    return {
      inputPayPwd: false,
      pay_pwd: '',
      orderItem: null,
    }
  },
  props: {
    orderList: Array,
  },
  methods: {
    deleteOrderClick(orderItem) {
      this.orderItem = orderItem
      var _this = this;
      Dialog.confirm({
        title: "是否确认取消订单",
        message: '取消订单后，餐费将退回您的账户，我司将不会制作和配送此订单！(注：需要输入支付密码确认取消订单)',
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            if (_this.memberInfo.type == 3) {
              _this.inputPayPwd = true;
            } else {
              await _this.ordinaryEmployeeCancelOrder();
            }
            done();
          } else {
            done();
          }
        },
      });
    },
    async ordinaryEmployeeCancelOrder() {
      var type = 1;
      var {id,ameal_num,bmeal_num} = this.orderItem;
      var args = {
        type,
        id,
        list: [
          {
            id,
            anum: -(ameal_num),
            bnum: -(bmeal_num)
          }
        ]
      }
      var res = await orderDetailUpdate(args);
      console.log(this.$parent);
      setTimeout(() => {
        this.$emit('refreshData');
      }, 1500);
      await Toast(res.msg);
    },
    async confirmDeleteOrder (action, done) {
      var _this = this;
      console.log('你来到了这里');
      if (action === "confirm") {
        var {orderItem,pay_pwd} = _this;
        var {order_num} = orderItem;
        _this.pay_pwd = '';
        var res = await cancelOrder({id: order_num,pay_pwd});
        console.log(res,'取消订单结果');
        if (res.status == "200") {
          await Toast("成功取消订单");
          setTimeout(() => {
            _this.$emit('refreshData');
          }, 1500);
        } else {
          Toast(res.msg);
        }
        done();
      } else {
        done();
      }
    },
    // 修改查看订单详情
    editorder(item,isModify) {
      var order_num = item.order_num || item.id;
      if (item.order_status != 2) {
        isModify = false;
      }
      this.$router.push({
        name: "edit",
        query: {order_num,isModify}
      });
    },
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
  filters: {
    generateOrderOperationBtns(orderStatus) {
      switch (orderStatus) {
      case 0:
      return '已取消';
      break;
      case 1:
      return '确认付款';
      break;
      case 2:
      return '修改订单';
      break;
      case 3:
      return '确认加餐';
      break;
      case 4:
      return '';
      break;
      case 5:
      return '确认收货';
      break;
      case 6:
      return '已完成';
      break;
      default:
      return '';
      break;
  }
    }
  }
}
</script>

<style scoped>
  .order_list {
  width: 97vw;
  margin: 3vw auto 0;
  background-color: white;
  border-radius: 1vw;
}
.order_list_top {
  width: 94vw;
  height: 8vw;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order_list_ordernum {
  font-size: 3vw;
  color: #1e1e1e;
  font-weight: bold;
}
.order_liststatus {
  font-size: 3vw;
  color: #ff0000;
}
.order_list_img {
  width: 23vw;
  height: 23vw;
  border-radius: 1vw;
}
.order_list_center {
  width: 94vw;
  margin: 3vw auto 0;
  display: flex;
  justify-content: space-between;
}
.order_list_name {
  font-size: 5vw;
  color: #1a1a1a;
  font-weight: bold;
}
.order_list_price {
  font-size: 4vw;
  color: #ffab33;
}
.order_list_num {
  font-size: 4vw;
  font-size: #666666;
}
.order_list_guige {
  width: 68vw;
  height: 23vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.order_list_guige_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order_list_bottom {
  width: 94vw;
  display: flex;
  margin: 5vw auto 0;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3vw;
}
.order_list_total {
  font-size: 3vw;
  color: #1a1a1a;
}
.order_list_total span {
  color: #ff2222;
}
.order_list_bto {
  width: 22vw;
  height: 8vw;
  background-color: #c30d23;
  border-radius: 1vw;
  font-size: 4vw;
  color: white;
  text-align: center;
  line-height: 8vw;
}
</style>