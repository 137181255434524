<template>
  <div class="order">
    <van-nav-bar title="订单" />
    <van-tabs
      sticky
      v-model="active"
      title-active-color="#c30d23"
      title-inactive-color="#1e1e1e"
      line-height="0"
      @click="tabChange"
    >
      <van-tab title="全部">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="memberInfo.type == 3 ? '待付款' : '待确认' ">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="制作中">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="加餐确认">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="配送中">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="待收货">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已完成">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="getOrderList"
            :offset="10"
          >
            <OrderCycleUnit @refreshData="onRefresh" :orderList="orderList" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { orderIndex } from "../../api/all";
import OrderCycleUnit from "./orderCycleUnit";
import { mapGetters } from 'vuex';
export default {
  created() {
    this.tabChange();
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
  data() {
    return {
      active: 0, //tabbar索引
      page: 0,
      orderList: [],
      loading: false,
      finished: false,
      isLoading: false,
      isLoading: false,
    };
  },
  components: { OrderCycleUnit },
  methods: {
    onRefresh() {
      this.page = 0;
      this.orderList = [];
      this.getOrderList();
    },
    tabChange(name, title) {
      this.page = 0;
      this.orderList = [];
      this.finished = false;
      this.getOrderList();
    },
    async getOrderList() {
      if (this.isLoading) return;
      this.isLoading = true;
      var order_status = this.active;
      var args = {
        limit: 20,
        page: this.page + 1,
      };
      if (order_status != 0) {
        // if (order_status == 5) {
        //   order_status = 6;
        // }
        args["order_status"] = order_status;
      }
      var res = await orderIndex(args);
      if (res.status == "200" && res.data.list.length > 0) {
        this.orderList = this.orderList.concat(res.data.list);
        this.page += 1;
        this.loading = false;
        this.isLoading = false;
      } else {
        this.finished = true;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.order {
  height: 100%;
  background: #f2f2f2;
}
</style>