<template>
  <div v-if="orderInfo" class="orderinfo">
    <van-nav-bar title="提交订单" left-arrow @click-left="onClickLeft" />
    <div class="orderinfo_status">{{orderInfo.order_status | convertOrderStatusToText}}</div>
    <div class="orderinfo_address">
      <div class="company">
        <div class="company_name">公司：{{orderInfo.inc_name}}</div>
        <div class="company_info">
          <img src="../../assets/phone.png" class="company_img" />
          <a :href="'tel:'+ orderInfo.inc_tel" class="company_phone">{{orderInfo.inc_tel}}</a>
        </div>
      </div>
      <div class="company_address">地址：{{orderInfo.address}}</div>
    </div>
    <div class="orderinfo_type">
      <div class="orderinfo_type_name">类型：{{orderInfo.type == 1 ? '午餐' : '晚餐'}}</div>
      <div class="orderinfo_type_name">时间：{{orderInfo.eat_time}}</div>
    </div>

    <div
        v-if="orderInfo.change_list && orderInfo.change_list.length > 0"
        class="change-list-box"
        style="padding-top: 3vw; margin-top: 3vw;"
      >
        <div class="melset_orderinfo_pay_title">订单修改信息</div>
        <div v-for="item in orderInfo.change_list" :key="item.id" class="change-list-item">
          <p>{{item.nickname}}</p>
          <p>{{item.meal_type}}餐{{item.type == 1 ? '+' : '-'}}{{item.meal_num}}</p>
        </div>
        <p>需要支付： {{orderInfo.notpay}}</p>
      </div>

<!-- <van-stepper min="0" v-model="item.num" /> -->
    <template v-if="memberInfo.type == 1">
      <div v-if="!isModifying">
        <div class="orderinfo_type">
          <div class="amelset_center_single">
            <div>A餐{{orderInfo.ameal_full}}</div>
            <div>x{{orderInfo.ameal_num}}</div>
          </div>
          <div class="amelset_center_single">
            <div>B餐{{orderInfo.bmeal_full}}</div>
            <div>x{{orderInfo.bmeal_num}}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="orderinfo_type">
          <div class="amelset_center_single">
            <div>A餐{{orderInfo.ameal_full}}</div>
            <van-stepper min="0" v-model="orderInfo.order_detail.a[0].num" />
          </div>
          <div class="amelset_center_single">
            <div>B餐{{orderInfo.bmeal_full}}</div>
            <van-stepper min="0" v-model="orderInfo.order_detail.b[0].num" />
          </div>
        </div>
      </div>

      <div class="melset_orderinfo">
        <div class="melset_orderinfo_title">订单信息</div>
        <div class="melset_orderinfo_text">订单编号：{{orderInfo.order_num}}</div>
        <div class="melset_orderinfo_text">下单时间：{{orderInfo.create_time}}</div>
        <div class="melset_orderinfo_text">
          <div>配送员：{{orderInfo.driver_name}}</div>
          <div class="melset_orderinfo_phone">
            <img src="../../assets/phone.png" class="melset_orderinfo_img" />
            <!-- <div>{{orderInfo.driver_phone}}</div> -->
            <a
              :href="'tel:'+ orderInfo.driver_phone"
              class="company_phone"
            >{{orderInfo.driver_phone}}</a>
          </div>
        </div>
      </div>
      <div class="float-btn-box">
        <div v-if="isModifying" class="melset_paybto" @click="updateOrderDetail">确认修改</div>
        <div v-if="orderInfo.order_status < 4 && !isModifying" class="melset_paybto"  @click="isModifying = true">修改订单</div>
      </div>
      
    </template>
    <template v-if="memberInfo.type == 3">
      <div v-if="!isModifying">
        <div class="amelset" v-if="orderInfo.order_detail.a.length > 0">
          <div class="amelset_top">
            <div class="amelset_variety">
              <div>A餐{{orderInfo.ameal_full}}</div>
              <div>￥{{orderInfo.single_price}}</div>
            </div>
            <div class="amelset_num">
              <div>购买数量</div>
              <div>{{orderInfo.ameal_num}}</div>
            </div>
          </div>
          <div class="amelset_center">
            <van-collapse v-model="activeNames">
              <van-collapse-item
                title="订餐名单"
                name="1"
                title-class="fold"
                :value="activeNames.length > 0 ? '收起' : '展开' "
                value-class="fold_right"
                :is-link="isAexpand"
              >
                <div
                  v-for="item in orderInfo.order_detail.a"
                  :key="'a' + item.name"
                  class="amelset_center_single"
                >
                  <div>{{item.name}}{{item.type == 3 ? ' [管理员]' : ''}}</div>
                  <div>x{{item.num}}</div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>

        <div class="amelset" v-if="orderInfo.order_detail.b.length > 0">
          <div class="amelset_top">
            <div class="amelset_variety">
              <div>B餐{{orderInfo.bmeal_full}}</div>
              <div>￥{{orderInfo.single_price}}</div>
            </div>
            <div class="amelset_num">
              <div>购买数量</div>
              <div>{{orderInfo.bmeal_num}}</div>
            </div>
          </div>
          <div class="amelset_center">
            <van-collapse v-model="activeBNames">
              <van-collapse-item
                title="订餐名单"
                name="1"
                title-class="fold"
                :value="activeBNames.length > 0 ? '收起' : '展开' "
                value-class="fold_right"
                :is-link="isAexpand"
              >
                <div
                  v-for="item in orderInfo.order_detail.b"
                  :key="'b' + item.name"
                  class="amelset_center_single"
                >
                  <div>{{item.name}}{{item.type == 3 ? ' [管理员]' : ''}}</div>
                  <div>x{{item.num}}</div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="amelset" v-if="orderInfo.order_detail.a.length > 0">
          <div class="amelset_top">
            <div class="amelset_variety">
              <div>A餐{{orderInfo.ameal_full}}</div>
              <div>￥{{orderInfo.single_price}}</div>
            </div>
            <div class="amelset_num">
              <div>购买数量</div>
              <div>{{orderInfo.ameal_num}}</div>
            </div>
          </div>
          <div class="amelset_center">
            <van-collapse v-model="activeNames">
              <van-collapse-item
                title="订餐名单"
                name="1"
                title-class="fold"
                :value="activeNames.length > 0 ? '收起' : '展开' "
                value-class="fold_right"
                :is-link="isAexpand"
              >
                <div
                  v-for="item in orderInfo.order_detail.a"
                  :key="'a' + item.name"
                  class="amelset_center_single"
                >
                  <div>{{item.name}}{{item.type == 3 ? ' [管理员]' : ''}}</div>
                  <van-stepper min="0" v-model="item.num" />
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>

        <div class="amelset" v-if="orderInfo.order_detail.b.length > 0">
          <div class="amelset_top">
            <div class="amelset_variety">
              <div>B餐{{orderInfo.bmeal_full}}</div>
              <div>￥{{orderInfo.single_price}}</div>
            </div>
            <div class="amelset_num">
              <div>购买数量</div>
              <div>{{orderInfo.bmeal_num}}</div>
            </div>
          </div>
          <div class="amelset_center">
            <van-collapse v-model="activeBNames">
              <van-collapse-item
                title="订餐名单"
                name="1"
                title-class="fold"
                :value="activeBNames.length > 0 ? '收起' : '展开' "
                value-class="fold_right"
                :is-link="isBexpand"
              >
                <div
                  v-for="item in orderInfo.order_detail.b"
                  :key="'b' + item.name"
                  class="amelset_center_single"
                >
                  <div>{{item.name}}{{item.type == 3 ? ' [管理员]' : ''}}</div>
                  <van-stepper min="0" v-model="item.num" />
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
      </div>

      <div class="melset_all">
        <div class="melset_all_title">共{{orderInfo.ameal_num + orderInfo.bmeal_num}}份订餐</div>
        <div class="melset_all_price">
          合计
          <span>￥{{orderInfo.total_price}}</span>
        </div>
      </div>
      <div class="melset_orderinfo">
        <div class="melset_orderinfo_title">订单信息</div>
        <div class="melset_orderinfo_text">订单编号：{{orderInfo.order_num}}</div>
        <div class="melset_orderinfo_text">下单时间：{{orderInfo.create_time}}</div>
        <div class="melset_orderinfo_text">
          <div>配送员：{{orderInfo.driver_name}}</div>
          <div class="melset_orderinfo_phone">
            <img src="../../assets/phone.png" class="melset_orderinfo_img" />
            <!-- <div>{{orderInfo.driver_phone}}</div> -->
            <a
              :href="'tel:'+ orderInfo.driver_phone"
              class="company_phone"
            >{{orderInfo.driver_phone}}</a>
          </div>
        </div>
        <div class="melset_orderinfo_text">发货时间：{{orderInfo.send_time}}</div>
        <div class="melset_orderinfo_text">到货时间：{{orderInfo.accept_time}}</div>
        <div>完成时间：{{orderInfo.finish_time}}</div>
      </div>
      <div class="melset_orderinfo_pay">
        <div class="melset_orderinfo_pay_title">支付方式</div>
        <div>支付方式：{{orderInfo.pay_way | convertPayWayToText}}</div>
        <div>支付时间：{{orderInfo.pay_time}}</div>
      </div>

      

      <!-- 确认更新订单 -->
      <div v-if="orderInfo.order_status > 0" class="float-btn-box">
        <div v-if="isModifying" class="melset_paybto" @click="updateOrderDetail">确认订单</div>
        <!-- 进行修改订单操作 -->
        <div v-if="orderInfo.order_status < 3 && !isModifying">
          <div
            v-if="orderInfo.is_default && memberInfo.type == 3"
            class="melset_paybto"
            @click="setPayType()"
          >确认付款</div>
          <div class="modify-confirm-box" v-else>
            <div class="modify-confirm-btn" @click="modifyOrderClick">修改订单</div>
            
            <div
              v-if="(orderInfo.notpay > 0 || orderInfo.order_status == 1) && memberInfo.type == 3"
              class="modify-confirm-btn"
              @click="setPayType(1)"
            >确认订单</div>
            <div v-if="orderInfo.order_status > 0 && orderInfo.order_status < 4" class="modify-confirm-btn" @click="deleteOrderClick">取消订单</div>
          </div>
        </div>
        <!-- 确认加餐 -->
        <div
          v-if="orderInfo.order_status == 3 && !isModifying && memberInfo.type == 3"
          class="melset_paybto"
          @click="setPayType()"
        >确认加餐</div>
        <!-- 确认加餐 -->
        <div
          v-if="orderInfo.order_status == 5 && memberInfo.type == 3"
          class="melset_paybto"
          @click="updateOrderStatus(orderInfo.id, 6)"
        >确认收货</div>
      </div>

      <!-- 输入密码 -->
      <van-overlay :show="show">
        <div class="wrapper">
          <div class="block">
            <div class="paypas_title">支付密码</div>
            <van-field
              type="password"
              class="paypas"
              v-model="pay_pwd"
              label
              placeholder="请输入支付密码"
              error-message
            />
            <div class="paypas_bto">
              <div class="paypas_cancel" @click="show = false">取消</div>
              <div @click="confirmPay" class="paypas_confim">确认</div>
            </div>
          </div>
        </div>
      </van-overlay>
    </template>
  </div>
</template>

<script>
import {
  orderView,
  orderDetailUpdate,
  orderPay,
  orderUpdate,
  cancelOrder
} from "../../api/all";
import { Toast, Dialog } from "vant";
import { mapGetters } from "vuex";
export default {
  created() {
    this.getOrderInfo(); //isModify
    var isModify = this.$route.query.isModify;
    if (isModify && this.orderInfo.status > 0) {
      this.modifyOrderClick();
    }
  },
  computed: {
    ...mapGetters(["memberInfo"]),
  },
  data() {
    return {
      isTypingPwdForDeleteOrder: false,
      isAexpand: false,
      isBexpand: false,
      isModifying: false,
      orderInfo: null,
      orderInfoBackup: null,
      show: false,
      pay_pwd: "",
      activeNames: [],
      activeBNames: [],
      payType: 1, //1 普通订单  2加单
    };
  },
  filters: {
    convertPayWayToText(args) {
      switch (args) {
        case 1:
          return "月结";
          break;
        case 2:
          return "预存款";
          break;
        default:
          return "";
          break;
      }
    },
  },
  methods: {
    routeToOrderList() {
      setTimeout(() => {
        this.onClickLeft();
      }, 1500);
    },
    deleteOrderClick() {
      var _this = this;
      Dialog.confirm({
        title: "是否确认取消订单",
        message: '取消订单后，餐费将退回您的账户，我司将不会制作和配送此订单！(注：需要输入支付密码确认取消订单)',
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            _this.isTypingPwdForDeleteOrder = true;
            _this.show = true;
            done();
          } else {
            done();
          }
        },
      });
      
    },
    async confirmDeleteOrder() {
      var { orderInfo, pay_pwd } = this;
      var {id} = orderInfo;
      this.pay_pwd = '';
      var res = await cancelOrder({id,pay_pwd});
      if (res.status == "200") {
        await this.getOrderInfo();
        Toast("成功取消订单");
        this.routeToOrderList();
      } else {
        Toast(res.msg);
      }
    },
    modifyOrderClick() {
      this.isModifying = true;
      this.activeNames.push("1");
      this.activeBNames.push("1");
    },
    async updateOrderStatus(id, status) {
      var args = { id, status };
      var res = await orderUpdate(args);
      if (res.status == "200") {
        Toast(res.msg || "操作成功");
        this.getOrderInfo();
        this.routeToOrderList();
      } else {
        Toast(res.msg || "操作失败");
      }
    },
    setPayType(type) {
      console.log(233555111);
      this.payType = this.orderInfo.order_status == 1 ? 1 : 2;
      if (!this.orderInfo.order_status == 1 && this.orderInfo.notpay == 0) {
        return Toast("无需支付");
      }
      if (this.memberInfo.type == 3) {
        this.show = true;
      }
    },
    async updateOrderDetail() {
      var type;
      if (this.memberInfo.type == 1) {
        type = 1;
      } else if (this.orderInfo.is_default == 1 && this.memberInfo.type == 3) {
        type = 2;
      } else {
        type = 3;
      }
      var changeOrderDetail = this.orderInfo.order_detail;
      var originalOrderDetail = this.orderInfoBackup.order_detail;
      var args = {
        type,
        id: this.orderInfo.id,
        list: [],
      };
      var totalANum = 0;
      var totalBNum = 0;
      if (!originalOrderDetail.a) {
        originalOrderDetail.a = [];
      }
      if (!originalOrderDetail.b) {
        originalOrderDetail.b = [];
      }
      // var cycleCount = originalOrderDetail.a.length > originalOrderDetail.b.length ? originalOrderDetail.a.length : originalOrderDetail.b.length;
      var isALonger =
        originalOrderDetail.a.length > originalOrderDetail.b.length;
      if (isALonger) {
        for (let aindex = 0; aindex < originalOrderDetail.a.length; aindex++) {
          var originalA = originalOrderDetail.a[aindex];
          var changedA = changeOrderDetail.a[aindex];
          console.log(originalA,changedA,"看这里");
          var id = originalA.id;
          var anum = changedA.num - originalA.num;
          var originalB;
          var changedB;
          var bnum;

          var bindex = originalOrderDetail.b.findIndex((item) => item.id == id);
          if (bindex != -1) {
            originalB = originalOrderDetail.b[bindex];
            changedB = changeOrderDetail.b[bindex];
            bnum = changedB.num - originalB.num;
          } else {
            bnum = 0;
          }
          totalANum += anum;
          totalBNum += bnum;
          var tempRes = {
            id,
            anum,
            bnum,
          };
          args.list.push(tempRes);
        }
      } else {
        for (let bindex = 0; bindex < originalOrderDetail.b.length; bindex++) {
          var originalB = originalOrderDetail.b[bindex];
          var changedB = changeOrderDetail.b[bindex];
          var id = originalB.id;
          var bnum = changedB.num - originalB.num;
          var originalA;
          var changedA;
          var anum;

          var aindex = originalOrderDetail.a.findIndex((item) => item.id == id);
          if (aindex != -1) {
            originalA = originalOrderDetail.a[bindex];
            changedA = changeOrderDetail.a[bindex];
            anum = changedA.num - originalA.num;
          } else {
            anum = 0;
          }
          totalANum += anum;
          totalBNum += bnum;
          var tempRes = {
            id,
            anum,
            bnum,
          };
          args.list.push(tempRes);
        }
      }
      var typeText = "是否确认";
      var aText = this.convertTypeText(totalANum);
      if (aText) {
        typeText += `A餐${aText}份`;
      }
      var bText = this.convertTypeText(totalBNum);
      if (bText) {
        typeText += `，B餐${bText}份`;
      }
      Dialog.confirm({
        title: "提示",
        message: typeText,
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            var res = await orderDetailUpdate(args);
            if (res.status == "200") {
              this.getOrderInfo();
              this.isModifying = false;
              this.routeToOrderList();
            }
            done();
            Toast(res.msg);
          } else {
            done();
          }
        },
      });
    },
    convertTypeText(num) {
      if (num == 0) {
        return false;
      }
      if (num >= 0) {
        return `加${num}`;
      } else {
        num = Math.abs(num);
        return `减${num}`;
      }
    },
    async confirmPay() {
      if (this.isTypingPwdForDeleteOrder) {
        this.isTypingPwdForDeleteOrder = false;
        this.show = false;
        this.confirmDeleteOrder();
        return;
      }
      var type = this.payType;
      var { orderInfo, pay_pwd } = this;
      this.pay_pwd = '';
      var args = {
        pay_pwd,
        order_num: orderInfo.id,
        type,
      };
      var res = await orderPay(args);
      if (res.status == "200") {
        
        await this.getOrderInfo();
        Toast("支付成功");
        this.routeToOrderList();
      } else {
        Toast(res.msg);
      }
      this.show = false;
      this.pay_pwd = "";
    },
    async getOrderInfo() {
      var order_num = this.$route.query.order_num;
      var res = await orderView({ id: order_num });
      if (res.status == "200") {
        this.orderInfoBackup = JSON.parse(JSON.stringify(res.data));
        var data = res.data;
        if (!data.order_detail.a) {
          data.order_detail.a = [];
        }
        if (!data.order_detail.b) {
          data.order_detail.b = [];
        }
        this.orderInfo = data;
        var temp = res.data;
      }
    },
    // 返回前一页面
    onClickLeft() {
      if (this.$route.query.fromBlank) {
        console.log("温柔");
        this.$router.replace({ path: "/meal" })
      }else {
        console.log("说的粉红色");
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.change-list-item {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.paypas_title {
  font-size: 4.533vw;
  text-align: left;
  padding: 4.8vw 0 0 5.467vw;
  color: #1e1e1e;
  font-weight: bold;
}
.paypas {
  width: 61.333vw;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 72.133vw;
  height: 37.067vw;
  background-color: white;
  border-radius: 3.733vw;
}
.orderinfo {
  height: 100%;
  overflow-x: hidden;
  background-color: #f2f2f2;
  padding-bottom: 17vw;
}
.orderinfo_status {
  width: 100%;
  height: 12vw;
  background-color: #c30d23;
  text-align: center;
  line-height: 12vw;
  font-size: 4vw;
  color: white;
}
.orderinfo_address {
  width: 97vw;
  height: 19vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
  margin: 3vw auto;
}
.company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93vw;
  margin: 4vw auto 3vw;
}
.company_name {
  font-size: 3vw;
  color: #3b3b3b;
}
.company_info {
  display: flex;
  align-items: center;
}
.company_img {
  width: 3vw;
  height: 3vw;
}
.company_phone {
  font-size: 3vw;
  color: #3b3b3b;
}
.company_address {
  width: 94vw;
  margin: 0 auto;
  font-size: 3vw;
  color: #3b3b3b;
  text-align: left;
}
.orderinfo_type {
  width: 97vw;
  padding: 0 3vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 19vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
  margin: 3vw auto 0;
}
.orderinfo_type_name {
  font-size: 3vw;
  color: #3b3b3b;
}
.amelset_top {
  width: 91vw;
  height: 18vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 1px solid #e5e5e5;
}
.amelset {
  width: 97vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
  margin: 3vw auto 0;
  padding-bottom: 4vw;
}
.amelset_variety {
  font-size: 3vw;
  width: 100%;
  color: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amelset_num {
  width: 100%;
  font-size: 3vw;
  color: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amelset_center {
  width: 91vw;
  margin: 0 auto;
}
.amelset_center_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2vw 0 4vw;
}
.amelset_center_list {
  width: 91vw;
  margin: 0 auto;
  font-size: 3vw;
  color: #3b3b3b;
}
.amelset_center_single {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.melset_all {
  width: 97vw;
  margin: 2vw auto;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vw;
  box-sizing: border-box;
}
.melset_all_title {
  font-size: 3vw;
  color: #1a1a1a;
}
.melset_all_price {
  font-size: 3vw;
  color: #1a1a1a;
}
.melset_all_price span {
  color: #ff2222;
}
.melset_orderinfo {
  width: 97vw;
  background-color: #ffffff;
  border-radius: 1vw;
  margin: 3vw auto 0;
  padding: 4vw 3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 3vw;
}
.melset_orderinfo_title {
  font-size: 3vw;
  color: #3b3b3b;
  font-weight: bold;
  margin-bottom: 5vw;
}
.melset_orderinfo_text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.667vw;
  width: 100%;
}
.melset_orderinfo_img {
  width: 3.467vw;
  height: 3.467vw;
}
.melset_orderinfo_phone {
  display: flex;
  align-items: center;
}
.change-list-box {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 1.333vw;
  padding: 3vw 3vw 0;
  align-items: flex-start;
  font-size: 3.467vw;
  margin: 0 1.33vw 3vw;
}
.change-list-box p {
  line-height: 3.467vw;
}
.melset_orderinfo_pay {
  width: 97.333vw;
  margin: 2.533vw auto;
  height: 27.333vw;
  background-color: #ffffff;
  border-radius: 1.333vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 3.467vw;
  padding: 0 3vw;
  box-sizing: border-box;
}
.melset_orderinfo_pay_title {
  font-weight: bold;
}
.melset_paybto {
  width: 84.4vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  text-align: center;
  line-height: 13.467vw;
  color: white;
  margin: 0 auto;
}
.paypas_bto {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 4.267vw;
  padding-right: 9.467vw;
  margin-top: 4.667vw;
}
.paypas_cancel {
  font-size: 4.267vw;
  color: #8c8c8c;
  margin-right: 4.533vw;
}
.paypas_confim {
  color: #c30d23;
}
.modify-confirm-box {
  display: flex;
  flex-flow: row nowrap;
  padding: 3vw;
}
.modify-confirm-btn {
  width: 30vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  text-align: center;
  line-height: 13.467vw;
  color: white;
  margin: 0 auto;
}
.float-btn-box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 3vw;
}
</style>