<template>
  <div v-if="financeItem">
    <van-nav-bar title="账单详情" left-arrow @click-left="onClickLeft" />
    <div class="detail_top">
      <img src="../../assets/consumption.png" class="detail_top_icon" />
      <div class="detail_top_type">{{financeItem.type | convertFinanceType}}</div>
      <div class="detail_top_price">{{financeItem.type | convertFinancePulsMinus}}{{financeItem.type == 9 ? '0' : financeItem.price}}</div>
    </div>
    <div class="detail_center">
      <div class="detail_center_single">
        <div class="detail_center_single_name">类型</div>
        <div class="detail_center_single_value">{{financeItem.type | convertFinanceType}}</div>
      </div>
      <div class="detail_center_single">
        <div class="detail_center_single_name">{{financeItem.type | convertFinanceType}}时间</div>
        <div class="detail_center_single_value">{{financeItem.create_time}}</div>
      </div>
      <div v-if="financeItem.type == 2 || financeItem.type == 3" class="detail_center_single">
        <div class="detail_center_single_name">订单号</div>
        <div class="detail_center_single_value">{{financeItem.order_num}}</div>
      </div>
    </div>
    <div v-if="financeItem.type == 2 || financeItem.type == 3" class="detail_bto" @click="godetail">订单详情</div>
  </div>
</template>

<script>
import {financeView} from '../../api/all'
export default {
  data() {
    return {
      financeItem: null,
    };
  },
  created() {
    this.getBillDetail();
  },
  // computed: {
  //   financeItem() {
  //     return this.$store.state.financeItem;
  //   }
  // },
  methods: {
    async getBillDetail() {
      var id = this.$route.query.id;
      var res = await financeView({id});
      console.log(res,1122);
      if (res.status == '200') {
        this.financeItem = res.data;
      }
    },
    // 返回前一页面
    onClickLeft() {
      if (this.$route.query.fromBlank) {
        this.$router.replace({ path: "/meal" })
      }else {
        this.$router.go(-1);
      }
    },
    // 跳转到订单详情
    godetail() {
      var order_num = this.financeItem.order_num;
      if (order_num == null) return;
      this.$router.push({
        name: "edit",
        query: {order_num}
      });
    },
  },
};
</script>

<style scoped>
.detail_top {
  height: 40.667vw;
  width: 90.4vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}
.detail_top_icon {
  width: 10.667vw;
  height: 10.667vw;
}
.detail_top_type {
  font-size: 4.267vw;
  color: #000000;
  margin: 2vw 0 2vw;
}
.detail_top_price {
  font-size: 6.4vw;
  color: #000000;
  font-weight: bold;
}
.detail_center {
  width: 90.4vw;
  margin: 5.333vw auto 0;
}
.detail_center_single {
  display: flex;
  align-items: center;
  margin-bottom: 4.667vw;
}
.detail_center_single_name {
  width: 20vw;
  font-size: 3.467vw;
  color: #666666;
  text-align: left;
}
.detail_center_single_value {
  font-size: 3.467vw;
  color: #000;
}
.detail_bto {
  width: 84.4vw;
  height: 11.867vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  color: white;
  text-align: center;
  line-height: 11.867vw;
  margin: 0 auto;
}
</style>