<template>
  <div style="overflow-x:hidden">
    <van-nav-bar title="点餐" />
    <div v-if="memberInfo && memberInfo.type == 3">
      <van-tabs v-model="tabsindex" line-width="32" line-height="4">
        <van-tab title="公司员工" title-active-color="#000000" title-inactive-color="#666666">
          <commonpage :tabsindex='tabsindex' />
        </van-tab>
        <van-tab title="个人点餐" title-active-color="#000000" title-inactive-color="#666666">
          <commonpage :tabsindex='tabsindex' />
        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <commonpage :tabsindex='2' />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import commonpage from './commonpage';
import { stopeatIndex,memberWxlogin } from "../../api/all";
export default {
  components: {commonpage},
  data() {
    return {
      tabsindex: 0, //导航栏Index
    };
  },
  computed: {
    ...mapGetters([
      'memberInfo',
    ]),
  },
};
</script>
