import axios from "axios";
import qs from "qs";
import { Toast, Dialog } from "vant";

/**
 * 状态标识
 * status: {
 *   101: token过期
 *   201: 错误
 * }
 * 测试用过期token => eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjbGllbnQueGhhZG1pbiIsImF1ZCI6InNlcnZlci54aGFkbWluIiwiaWF0IjoxNTc4MzAwODg5LCJleHAiOjI1NzgzMDA4ODgsInVpZCI6MX0.3lF3U-dCzrExsbUCL1rH6QGGiA2ZLcyA1Nvj7byaQeo
 */

class Service {
  constructor() {
    this.service = this._setUpService();
  }

  /**
   * GET
   * @param url        传输地址
   * @param data      传输数据
   * @param isLoading 显示加载
   * @param tips      加载提示
   * @returns {*}
   */
  get(url = "", data, isLoading = true, tips = "加载中") {
    if (isLoading) {
      Toast.loading({
        message: tips,
        overlay: true, // 背景遮罩
        forbidClick: true, // 禁用背景点击
        duration: 0 // 手动取消
      });
    }
    const option = {
      url,
      params: data,
      method: "get"
    };
    return this._request(option);
  }

  /**
   * POST
   * @param url       传输地址
   * @param data      传输数据
   * @param type      请求类型
   * @param isLoading 显示加载
   * @param tips      加载提示
   */
  post(
    url = "",
    {
      data = {},
      type = "x-www-form-urlencoded",
      isLoading = true,
      tips = "加载中"
    }
  ) {
    if (isLoading) {
      Toast.loading({
        message: tips,
        overlay: true, // 背景遮罩
        forbidClick: true, // 禁用背景点击
        duration: 0 // 手动取消
      });
    }
    const option = {
      url,
      method: "post"
    };
    if (type === "json") {
      // 发送json
      option.headers["Content-Type"] = "application/json;charset=UTF-8";
      option.data = data;
    } else if (type === "form-data") {
      // 文件发送
      option.headers["Content-Type"] = "multipart/form-data;charset=UTF-8";
      const form = new FormData();
      for (const key in data) {
        const value = data[key];
        form.append(key, value);
      }
      option.data = form;
    } else {
      // 表单发送
      option.data = qs.stringify(data);
    }
    return this._request(option);
  }
  // 创建请求服务
  _setUpService() {
    const isProEnv = process.env.NODE_ENV === "production";

    const testToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjYyMDR9.zZb61MumjMyLtRcJbyDF_EIgsfkAXBtw8G0dF3kQQtM"
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MX0.isfeeg7jLm8HfsTaavw8dC2H5VP9zDgcnKSwrsjKRsM";
    // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MX0.isfeeg7jLm8HfsTaavw8dC2H5VP9zDgcnKSwrsjKRsM";
    // const testToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTA1MjR9.NFbOQlPwU8_RsT3Rdq63aFYB8vqmUEoJ3fdmAGGuXz0'
    // const testToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjYyMDh9.W_NnPvRI8J9qtePNxfmNT9ms6MD4gCXDGPwwLIv_CAI'
    // const testToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NTIwMn0.qAjljR8A3fVC6fHe07QWzjStv-sSkgXOfM-r5di_exw'
    // const devUrl = 'https://api.up-petroleum.com/api/v2';
    const devUrl = 'https://chuniang.qupinmall.com/api/';
    // const devUrl = "https://api.up-petroleum.com/api/v2/";
    // const devUrl = "http://ittun819.ittun.com/api/v2/";

    const proUrl = "https://chuniang.qupinmall.com/api/";
    // const proUrl = "https://apitest.up-petroleum.com/api/v2/";

    let baseURL = "";

    if (isProEnv) {
      baseURL = devUrl;
    } else {
      baseURL = devUrl;
    }

    const config = {
      baseURL: baseURL,
      // withCredentials: true, // send cookies when cross-domain requests
      timeout: 30 * 1000
    };

    const service = axios.create(config);

    // request interceptor
    service.interceptors.request.use(
      config => {
        if (isProEnv) {
          config.headers["Authorization"] = localStorage.getItem("token")||'';
        } else {
          // token ?
          //   config.headers['token'] = localStorage.getItem('token')
          //   :
          config.headers["Authorization"] = localStorage.getItem("token")||'';
          // config.headers["Authorization"] = testToken;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // response interceptor
    service.interceptors.response.use(
      response => {
        Toast.clear();
        const res = response.data;
        // code不为0,判断为错
        if (res.status !== '200') {
          // switch (res.status) {
          //   case 1: // 后台常规验证错误
          //     console.error(res);
          //     Toast(res.msg);
          //     break;
          // }
          // return Promise.reject(res.msg || "Error");
          return res;
        } else {
          return res;
        }
      },
      error => {
        Toast.clear();
        return Promise.reject(error);
      }
    );
    return service;
  }
  // 发送请求服务
  async _request(option) {
    return this.service.request(option).catch((err)=>{console.log(err);});
  }
}

const service = new Service();
export default service;
