<template>
  <div>
    <van-nav-bar :title=" pageTitle[mealType] + '默认数量' " left-arrow @click-left="onClickLeft" />
    <div class="default_info">
      <div class="default_list">
        <div>A餐辣菜</div>
        <van-stepper min="0" v-model="a_meal_num" />
      </div>
      <div class="default_list">
        <div>B餐不辣菜</div>
        <van-stepper min="0" v-model="b_meal_num" />
      </div>
      <div class="default_list">
        <div>默认点餐</div>
        <van-switch :value="checked" size="20px" active-color="#c30d23" @input="onInput" />
      </div>
    </div>
    <div @click="confirmBtnClick" class="default_bto">确认</div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { mapGetters } from 'vuex';
import { memberUpdate,incUpdate } from "../../api/all";
export default {
  created() {
    this.mealType = this.$route.query.mealType;
    var tabsindex = this.$route.query.tabsindex;
    var {
      am_a_default,
      am_b_default,
      pm_a_default,
      pm_b_default,
      pm_is_default,
      am_is_default,

      inc_am_is_default,
      inc_pm_is_default,
      inc_am_a_default,
      inc_am_b_default,
      inc_pm_a_default,
      inc_pm_b_default,
    } = this.memberInfo
    if (tabsindex == 0) {
      if (this.mealType == '1') {
        this.checked = inc_am_is_default == 1;
        this.a_meal_num = inc_am_a_default;
        this.b_meal_num = inc_am_b_default;
      } else {
        this.checked = inc_pm_is_default == 1;
        this.a_meal_num = inc_pm_a_default;
        this.b_meal_num = inc_pm_b_default;
      }
    } else {
      if (this.mealType == '1') {
        this.checked = am_is_default == 1;
        this.a_meal_num = am_a_default;
        this.b_meal_num = am_b_default;
      } else {
        this.checked = pm_is_default == 1;
        this.a_meal_num = pm_a_default;
        this.b_meal_num = pm_b_default;
      }
    }
      
  },
  data() {
    return {
      value: 1,
      a_meal_num: 0,
      b_meal_num: 0,
      checked: true,
      mealType: '1',
      pageTitle: {
        '1': '午餐',
        '2': '晚餐',
      },
      am_a_default: 0,
      am_b_default: 0,
      pm_a_default: 0,
      pm_b_default: 0,
      pm_is_default: 0,
      am_is_default: 0,
    };
  },
  computed: {
  // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'memberInfo',
    ])
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async confirmBtnClick() {
      var mealType = this.$route.query.mealType;
      var tabsindex = this.$route.query.tabsindex;
      var {a_meal_num,b_meal_num,checked} = this;
      if (a_meal_num == 0 && b_meal_num == 0) {
        return Toast("点餐数量不能为0");
      }
      var args = {};
      if (mealType == '1') {
        args = {am_a_default:a_meal_num,am_b_default:b_meal_num,am_is_default:checked ? 1 : 0};
        
      } else if (mealType == '2') {
        args = {pm_a_default:a_meal_num,pm_b_default:b_meal_num,pm_is_default:checked ? 1 : 0};
      }
      var res;
      if (tabsindex == '0') {
        res = await incUpdate(args);
      } else {
        res = await memberUpdate(args);
      }
      if (res.status == '200') {
        await this.$store.dispatch("getMemberInfo");
        this.$router.go(-1);
      }
      Toast(res.msg);
    },
    onInput(checked) {
      if (checked) {
        Dialog.confirm({
          title: "提示",
          message: "是否开启默认点餐",
        }).then(() => {
          this.checked = checked;
        });
      } else {
        this.checked = checked;
      }
    },
  },
};
</script>

<style  scoped>
.default_list {
  width: 92vw;
  margin: 0 auto 6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.default_info {
  margin-top: 11vw;
}
.default_bto {
  width: 84vw;
  height: 13vw;
  background-color: #c30d23;
  border-radius: 1vw;
  font-size: 5vw;
  color: white;
  text-align: center;
  line-height: 13vw;
  margin: 0 auto;
}
</style>