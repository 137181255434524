<template>
  <div>
    <van-nav-bar title="通知" />
    <div>
      <div v-for="item in noticeList" :key="item.id" class="noticelist" @click="godetail(item.id)">
        <div class="noticelist_top">
          <div class="noticelist_top_title">{{item.title}}</div>
          <div :class="item.is_read ? 'noticelist_top_date' : 'noticelist_top_date_notread'">{{item.create_time}}</div>
        </div>
        <div v-if="item.am_start_time" class="noticelist_content">午餐停餐时间：{{item.am_start_time}}至{{item.am_end_time}}</div>
        <div v-if="item.pm_start_time" class="noticelist_content">晚餐停餐时间：{{item.pm_start_time}}至{{item.pm_end_time}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { noticerecordIndex } from "../../api/all";
export default {
  created() {
    this.getNoticeList();
  },
  activated() {
    this.page = 0;
    this.noticeList = [];
    this.getNoticeList();
  },
  data() {
    return {
      page: 0,
      noticeList: [],
    };
  },
  methods: {
    async getNoticeList() {
      var res = await noticerecordIndex({page: this.page+1,limit: 20});
      if (res.status == '200' && res.data.list.length > 0) {
        this.page = this.page + 1;
        this.noticeList = this.noticeList.concat(res.data.list);
      }
    },
    // 跳转详情
    godetail(id) {
      this.$router.push({ name: "detail",query: {id} });
    },
  },
};
</script>

<style scoped>
.noticelist {
  margin: 3vw auto 0;
  width: 97vw;
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 1px #e5e5e5;
}
.noticelist_top {
  width: 95vw;
  height: 12vw;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.noticelist_top_title {
  font-size: 4vw;
  color: #2d2d2d;
}
.noticelist_top_date,.noticelist_top_date_notread {
  font-size: 3vw;
  color: #999999;
  position: relative;
}
.noticelist_top_date_notread::after {
  position: absolute;
  content: "";
  width: 2vw;
  height: 2vw;
  background-color: #ff0000;
  right: -1vw;
  top: -1vw;
  border-radius: 50%;
}
.noticelist_content {
  color: #666666;
  line-height: 12vw;
  text-align: left;
  font-size: 3vw;
  height: 12vw;
  width: 95vw;
  margin: 0 auto;
  border-bottom: 1px solid #e5e5e5;
}
.noticelist_content:last-child {
  border: none;
}
</style>