<template>
  <div class="list_back">
    
    <van-checkbox-group class="send-list-outer-box" v-model="result" ref="order">
      <div v-if="orderDriverList.length == 0" style="padding: 6vw;">暂无数据</div>
      <div v-for="item in orderDriverList" :key="item.id" class="list_item">
        <div class="order_top">
          <van-checkbox
          :disabled="type > 2"
            :name="item.id"
            shape="square"
            checked-color="#cf1a3b"
          >订单号:{{ item.order_num }}</van-checkbox>
          <span>{{ send_type[type-1].name }}</span>
        </div>
        <div class="list_info">
          <div class="info_item">
            <span class="grey_text">企业：{{ item.inc_name }}</span>
            <span>
              <img src="../../assets/phone.png" class="tel_icon" />
              <a :href="'tel:'+ item.tel" class="company_phone">{{item.tel}}</a>
            </span>
          </div>
          <div class="info_item">
            <span class="grey_text">地址：{{ item.address }}</span>
            <span></span>
          </div>
          <div class="info_item">
            <span class="grey_text">{{ item.ameal_simple }}</span>
            <span class="red_count">{{ item.ameal_num }}份</span>
          </div>
          <div class="info_item">
            <span class="grey_text">{{ item.bmeal_simple }}</span>
            <span class="red_count">{{ item.bmeal_num }}份</span>
          </div>
        </div>
        <div class="grey_text all_count">
          <span>共{{ item.ameal_num + item.bmeal_num }}份订餐</span>
          <div class="action_btn" v-if="type == 1" @click="updateOrder(item.id,4)">
            <span>开始配送</span>
          </div>
          <div class="action_btn" v-if="type == 2" @click="updateOrder(item.id,5)">
            <span>已送达</span>
          </div>
        </div>
      </div>
    </van-checkbox-group>
    <div v-if="type < 3 && orderDriverList.length > 0" class="batch_back">
      <div class="batch_btn" @click="selAll()">全选</div>
      <div class="batch_btn" v-if="type == 1" @click="updateAll(4)">一键配送</div>
      <div class="batch_btn" v-if="type == 2" @click="updateAll(5)">一键送达</div>
    </div>
  </div>
</template>

<script>
import { orderDriverList, orderUpdate } from "../../api/all";
import { Toast } from "vant";
export default {
  name: "SendList",
  data() {
    return {
      all: false,
      result: [],
      send_type: [{ name: "待配送" }, { name: "配送中" }, { name: "已送达" }, { name: "已完成" }],
      sendStatus: [2, 4, 5, 6],
      page: 0,
      orderDriverList: [],
      searchKey: ''
    };
  },
  props: {
    search: String,
  },
  mounted() {
  },
  created() {
    this.getOrderDriverList();
  },
  methods: {
    searchNow(searchKey) {
      this.page = 0;
      this.orderDriverList = [];
      this.searchKey = searchKey;
      this.getOrderDriverList();
    },
    async getOrderDriverList() {
      // 订单状态  0 已取消  1待制作/待配送     2待收货/配送中    3已收货/已完成
      var args = {
        page: this.page + 1,
        limit: 20,
        order_status: this.sendStatus[this.type - 1]
      };
      if (!isNaN(this.searchKey)) {
        args['order_num'] = this.searchKey;
      }else {
        args["name"] = this.searchKey;
      }
      var res = await orderDriverList(args);
      if (res.status == "200" && res.data.list.length > 0) {
        this.page = this.page + 1;
        this.orderDriverList = this.orderDriverList.concat(res.data.list);
      }
    },
    async updateOrder(id, status) {
      var args = { id, status };
      var res = await orderUpdate(args);
      if (res.status == "200") {
        Toast(res.msg || "操作成功");
        this.page = 0;
        this.orderDriverList = [];
        await this.getOrderDriverList();
        window.location.href = window.location.href;
      } else {
        Toast(res.msg || "操作失败");
      }
      setTimeout(() => {
        this.$store.dispatch("getDriverOnGoing");
      }, 666);
    },
    async updateAll(status) {
      await Promise.all(this.result.map((id) => orderUpdate({ id, status })));
      this.result = [];
      this.page = 0;
      this.orderDriverList = [];
      this.getOrderDriverList();
      setTimeout(() => {
        this.$store.dispatch("getDriverOnGoing");
      }, 666);
      window.location.href = window.location.href;
    },
    finishSend(id) {
      alert("完成" + id);
    },
    finishAll() {
      alert("全部完成" + this.result.join(","));
    },
    selAll() {
      if (this.result.length != this.orderDriverList.length) {
        this.all = true;
      } else {
        this.all = !this.all;
      }
      this.$refs.order.toggleAll(this.all);
    },
  },
  props: {
    type: Number,
  },
};
</script>

<style scoped>
.company_phone {
  font-size: 3vw;
  color: #3b3b3b;
}
.list_back {
  padding: 1vw;
}
.list_item {
  background-color: #ffffff;
  border-radius: 1vw;
  border: solid 0vw #e5e5e5;
  padding: 1vw;
  margin-top: 3vw;
}
.order_top {
  border-bottom: 1px solid #e5e5e5;
  padding: 2vw 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vw 3vw;
  width: 100%;
}
.grey_text {
  color: #666666;
  font-size: 3vw;
}
.red_count {
  font-size: 3vw;
  color: #c30d23;
}
.tel_text {
  color: #3b3b3b;
  font-size: 3vw;
}
.all_count {
  padding: 3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16vw;
}
.action_btn {
  width: 22vw;
  height: 8vw;
  background-color: #c30d23;
  border-radius: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4vw;
}
.send-list-outer-box {
  padding-bottom: 15vw;
}
.batch_btn {
  width: 43vw;
  height: 12vw;
  background-color: #c30d23;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 5vw;
}
.batch_back {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 17vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tel_icon {
  width: 3vw;
  height: 3vw;
  margin-right: 1vw;
}
</style>
