import Vue from 'vue'
import store from "../store";
import Router from 'vue-router'
import login from '@/components/login'
import employeeInitInfo from '@/components/employeeInitInfo'
import tabbar from '@/components/tabbar'
import drivertabbar from '@/components/driverTabbar'
import meal from '@/components/meal/index'
import notmeal from '@/components/notmeal/index'
import order from '@/components/order/index'
import notice from '@/components/notice/index'
import mine from '@/components/mine/index'
import Default from '@/components/meal/default'
import apply from '@/components/notmeal/apply'
import detail from '@/components/notice/detail'
import edit from '@/components/order/edit'
import bill from '@/components/mine/bill'
import billdetail from '@/components/mine/billdetail'
import paymentpwd from '@/components/mine/paymentpwd'
import editpwd from '@/components/mine/editpwd'
import companypeople from '@/components/mine/companypeople'
import editnotes from '@/components/mine/editnotes'
import modifyinfo from '@/components/mine/modifyinfo'
import send from '@/components/driverTabbarViews/Send'
import recovery from '@/components/driverTabbarViews/Recovery'
import RecoveryList from '@/components/driverTabbarViews/RecoveryList'
import SendRecovery from '@/components/driverTabbarViews/SendRecovery'
import my from '@/components/driverTabbarViews/My'
import { Toast } from 'vant'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/employeeInitInfo',
      name: 'employeeInitInfo',
      component: employeeInitInfo
    },
    {
      path: "/tabbar",
      name: "tabbar",
      component: tabbar,
      redirect: "/meal",
      children: [
        {
          path: "/meal",
          name: "meal",
          component: meal
        },
        // 年审订单
        {
          path: "/notmeal",
          name: "notmeal",
          component: notmeal
        },
        // 年审订单
        {
          path: "/notice",
          name: "notice",
          component: notice
        },
        // 年审订单
        {
          path: "/order",
          name: "order",
          component: order
        },
        // 年审订单
        {
          path: "/mine",
          name: "mine",
          component: mine
        },
      ]
    },
    {
      path: "/driver_tabbar",
      name: "drivertabbar",
      component: drivertabbar,
      redirect: "/send",
      children: [
        {
          path: "/send",
          name: "send",
          component: send
        },
        // 年审订单
        {
          path: "/recovery",
          name: "recovery",
          redirect: "/recoveryList",
          component: recovery,
          children: [
            {
              path: "/recoveryList",
              name: "recoveryList",
              component: RecoveryList
            },
            {
              path: "/sendRecovery",
              name: "sendRecovery",
              component: SendRecovery
            }
          ]
        },
        // 年审订单
        {
          path: "/my",
          name: "my",
          component: my
        },
      ]
    },
    {
      path: '/meal/default',
      name: 'Default',
      component: Default
    },
    // 停餐申请
    {
      path: '/notmeal/apply',
      name: 'apply',
      component: apply
    },
    // 通知详情
    {
      path: '/notice/detail',
      name: 'detail',
      component: detail
    },
    // 订单详情
    {
      path: '/order/edit',
      name: 'edit',
      component: edit
    },
    // 账单明细
    {
      path: '/mine/bill',
      name: 'bill',
      component: bill
    },
    // 账单详情
    {
      path: '/mine/billdetail',
      name: 'billdetail',
      component: billdetail
    },
    // 支付密码
    {
      path: '/mine/paymentpwd',
      name: 'paymentpwd',
      component: paymentpwd
    },
    // 修改支付密码
    {
      path: '/mine/editpwd',
      name: 'editpwd',
      component: editpwd
    },
    // 公司人员
    {
      path: '/mine/companypeople',
      name: 'companypeople',
      component: companypeople
    },
    // 修改备注
    {
      path: '/mine/editnotes',
      name: 'editnotes',
      component: editnotes
    },
    // 修改信息
    {
      path: '/mine/modifyinfo',
      name: 'modifyinfo',
      component: modifyinfo
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.path == '/' || to.path == '/employeeInitInfo') {
    next();
    return;
  }
  if (!localStorage.getItem("token")) {
    Toast("您尚未登录");
    var oldRole = localStorage.getItem("role");
    if (!oldRole) {
      this.$router.replace({ path: "/employeeInitInfo" });
    }else {
      window.location.href = `${window.location.origin}/#/?role=${oldRole}`;
    }
    return;
  }else {
    if (!store.state.memberinfo) {
      await store.dispatch("getMemberInfo");
    }
  }
  next();
});

export default router;