<template>
  <div class="password">
    <van-nav-bar title="修改备注" left-arrow @click-left="onClickLeft" />
    <div>
      <div class="password_all">
        <div class="password_name">备注名</div>
        <div class="password_input">
          <input v-model="modifyArgs.nickname" type="text" class="password_value" placeholder="请输入备注名" />
        </div>
      </div>
      <div class="password_all">
        <div class="password_name">部门</div>
        <div class="password_input">
          <input v-model="modifyArgs.department" type="text" class="password_value" placeholder="请输入部门" />
        </div>
      </div>
      <div class="password_all">
        <div class="password_name">电话</div>
        <div class="password_input">
          <input v-model="modifyArgs.phone" type="text" class="password_value" placeholder="请输入号码" />
        </div>
      </div>
      <div class="confim" @click="confirmUpdate">保存修改</div>
    </div>
  </div>
</template>

<script>
import { memberUpdate } from "../../api/all";
import { Toast } from 'vant';
export default {
  data() {
    return {
      modifyArgs: {
        nickname: '',
        department: '',
        phone: '',
        id: '',
      }
    };
  },
  created() {
    var {nickname,department,phone,id} = this.$route.query;
    console.log(nickname,department,phone,id);
    this.modifyArgs = {nickname: nickname||'',department:department||'',phone:phone||'',id};
  },
  methods: {
    // 登陆跳转
    async confirmUpdate() {
      var {modifyArgs} = this;
      if (modifyArgs.nickname.length == 0 || modifyArgs.department.length == 0 || modifyArgs.phone.length == 0) {
        Toast("请填写完整信息！");
        return;
      }
      Toast.loading();
      var res = await memberUpdate(modifyArgs);
      if (res.status == '200') {
        await this.$store.dispatch("getMemberInfo");
        this.$router.go(-1);
      }
      Toast.clear();
      Toast(res.msg);
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.password {
  height: 100%;
  background-color: #f2f2f2;
}
.password_name {
  font-size: 3.467vw;
  color: #333333;
  text-align: left;
  padding-left: 2.933vw;
  margin: 4.4vw 0 2.4vw;
}
.password_input {
  width: 100%;
  height: 11.733vw;
  background-color: white;
}
.password_all {
  width: 97.333vw;
  margin: 0 auto;
}
.password_value {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  padding-left: 2.8vw;
  box-sizing: border-box;
}
.confim {
  width: 84.4vw;
  height: 13.467vw;
  background-color: #c30d23;
  border-radius: 1.333vw;
  font-size: 4.8vw;
  line-height: 13.467vw;
  text-align: center;
  color: white;
  margin: 6.533vw auto 0;
}
</style>