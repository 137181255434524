exports.install = function (Vue, options) {
  Vue.prototype.$getUrlKey = function (name) {
    console.log(window.location.href,233);
    console.log(location.href,666);
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          window.location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  },
    // https://chuniange.qupinmall.com/?code=071PHB000HdzeK18ql000AXx7Z2PHB0X&state=STATE#/
    Vue.prototype.$getCodeFromHref = function (){
        var href = window.location.href;
        var code;
        if (href.includes("com/?code")) {
          var url = href.substring(href.indexOf("com/?code")+5); //vue自动在末尾加了 #/ 符号，截取去掉
          var jingPosit = url.indexOf("#/"); //获取域名结束的位置
          var url = url.substring(0, jingPosit);//url左侧部分
          var urlMap = {};
          var urlQueryList = url.split("&");
          urlQueryList.forEach(element => {
            var res = element.split("=");
            urlMap[res[0]] = res[1];
          });
          code = urlMap.code;
        }
        if (href.includes("080/?code")) {  //url包括 com/?code 证明为从微信跳转回来的
          var url = href.substring(href.indexOf("080/?code")+5); //vue自动在末尾加了 #/ 符号，截取去掉
          var jingPosit = url.indexOf("#/"); //获取域名结束的位置
          var url = url.substring(0, jingPosit);//url左侧部分
          var urlMap = {};
          var urlQueryList = url.split("&");
          urlQueryList.forEach(element => {
            var res = element.split("=");
            urlMap[res[0]] = res[1];
          });
          code = urlMap.code;
        }
        return code;
    };
};