<template>
  <div style="height:100%">
    <div class="page_content_box">
      <router-view />
    </div>
    <van-tabbar v-model="active" active-color="#c30d23">
      <van-tabbar-item replace v-for="(item, index) in tabbarList" :key="index" :to="item.path">
        <span>{{item.name}}</span>
        <img slot="icon" slot-scope="props" :src="props.active ? item.inactive : item.active" />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import icon1 from "@/assets/icon/send.png";
import icon2 from "@/assets/icon/recovery.png";
import icon5 from "@/assets/icon/icon5.png";
import selecticon1 from "@/assets/icon/send_sel.png";
import selecticon2 from "@/assets/icon/recovery_sel.png";
import selecticon5 from "@/assets/icon/selecticon5.png";
export default {
  created() {
    var tempActive = this.tabbarList.findIndex(item => {
      return item.path == this.$route.path;
    });
    this.active = tempActive;
  },
  data() {
    return {
      active: 0, //当前选中的index
      tabbarList: [
        {
          path: "/send",
          name: "配送",
          active: icon1,
          inactive: selecticon1,
        },
        {
          path: "/recovery",
          name: "回收",
          active: icon2,
          inactive: selecticon2,
        },
        {
          path: "/my",
          name: "我的",
          active: icon5,
          inactive: selecticon5,
        },
      ],
    };
  },
  //监听路由变化
  watch: {
    $route(to) {
      this.activeTab(to.path);
    },
  },
  methods: {
    activeTab(path) {
      var index = this.tabbarList.map((item) => item.path).indexOf(path);
      if (index != -1) {
        this.active = index;
      }
    },
  },
};
</script>

<style scoped>
.page_content_box {
  position: fixed;
  background-color: #f2f2f2;
  bottom: 15vw;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
</style>